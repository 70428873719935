.c-hotel-sheet-room {
  margin-top: 20px;

  &__content {
    position: relative;
    margin-bottom: 10px;
    padding: 10px 0;
  }

  &__content-room {
    box-shadow: 2px 2px 0 0 rgba(0, 28, 83, 0.1);
    display: table;
    width: 100%;
    background: $color-white;
  }

  &__content-image {
    display: table-cell;
    width: 50%;
    min-height: 325px;
    overflow: hidden;
    position: relative;
    vertical-align: top;

    img {
      display: none;
    }
  }

  &__content-camera {
    position: absolute;
    bottom: 20px;
    right: 35px;
    height: 30px;
    background: $color-black;
    padding: 7px;
    font-size: 12px;

    > i {
      margin-left: 0;
      position: static;
      display: inline-block;
      font-size: 16px;
      top: 2px;
      color: $color-white;
    }
  }

  &__content-content {
    display: table-cell;
    width: 50%;
    padding: 10px 20px 10px 20px;
    vertical-align: top;
  }

  &__content-info {
    min-height: 250px;
    margin-bottom: 10px;
  }

  &__content-name {
    margin-top: 10px;
    margin-bottom: 10px;

    > a {
      color: $color-black;
      font-size: 20px;
      line-height: 26px;
      font-weight: 700;
      text-decoration: none;
    }
  }

  &__content-description {
    margin-bottom: 12px;
    min-height: 85px;
    font-size: 15px;
    line-height: 1.4;
  }

  &__content-services {
    width: 100%;
    float: left;
  }

  &__services-list {
    margin: 0 -10px;
    padding-left: 0;
  }

  &__service {
    display: table;
    position: relative;
    padding: 3px 10px;
    line-height: 15px;
    width: 50%;
    float: left;

    i {
      display: inline-block;
      vertical-align:middle;
      width: 25px;
      font-size:20px;
      font-family: "icons_services" !important;
    }
  }

  &__content-button {
    clear: both;
    width: 100%;
    padding: 5px 0;
    text-align: right;
  }

  &__btn-container {
    margin-bottom: 20px;
    text-align: center;
  }

  // Quizas se pudiera hacer un mixin o alguna función reducida, es mucho código repetido o similar.

  &--level &__content-room,
  &--redlevel &__content-room,
  &--me &__content-room,
  &--family-concierge &__content-room,
  &--royal-service &__content-room,
  &--the-reserve &__content-room
   {
    border-width: 2px;
    border-style: solid;

    .link-see-more {
      border: 1px solid;
      padding: 14px;
      float: left;
      margin: 0px 0 15px;
      text-decoration: none;
      text-align: center;
    }

    &:before {
      content: "";
      position: absolute;
      top: 25px;
      left: -10px;
      display: block;
      background-color: $color-white;
      box-shadow: 0px 1px 2px black;
      background-size: auto;
      background-repeat: no-repeat;
      z-index: 1;
    }
  }

  &--level &__content-room {
    border-color: $color-level;
    &:before {
      width: 134px;
      height: 70px;
      background-position: center;
      background-image: url("https://www1.melia.com/img/brand-logos/level.svg");
      background-size: 75%;
      border: 2px solid $color-level;
    }
    & .link-see-more {
      border-color: $color-level;
      color: $color-level;
    }
    & .btn-primary {
      background-color: $color-level;
      border-color: $color-level;
    }
  }

  &--redlevel &__content-room {
    border-color: $color-redlevel;
    &:before {
      width: 140px;
      height: 85px;
      background-position: 50% 30%;
      background-image: url("https://www1.melia.com/img/brand-logos/redlevel.svg");
      background-size: 65%;
    }
    & .link-see-more {
      border-color: $color-redlevel;
      color: $color-redlevel;
    }
    & .btn-primary {
      background-color: $color-redlevel;
      border-color: $color-redlevel;
    }
  }

  &--me &__content-room {
    border-color: $color-me;
    &:before {
      width: 120px;
      height: 85px;
      background-position: 50% 0;
      background-image: url("https://www1.melia.com/img/brand-logos/me.svg");
      background-size: 65%;
    }
    & .link-see-more {
      border-color: $color-me;
      color: $color-me;
    }
    & .btn-primary {
      background-color: $color-me;
      border-color: $color-me;
    }
  }

  &--family-concierge &__content-room {
    border-color: $color-family;
    &:before {
      width: 140px;
      height: 85px;
      background-position: 50% 30%;
      background-image: url("https://www1.melia.com/img/brand-logos/family-concierge-by-paradisus.svg");
      background-size: 65%;
      border: 2px solid $color-family;
    }
    & .link-see-more {
      border-color: $color-family;
      color: $color-family;
    }
    & .btn-primary {
      background-color: $color-family;
      border-color: $color-family;
    }
  }

  &--royal-service &__content-room {
    border-color: $color-royal;
    &:before {
      width: 140px;
      height: 85px;
      background-position: 50% 30%;
      background-image: url("https://www1.melia.com/img/brand-logos/royal-service-by-paradisus.svg");
      background-size: 65%;
      border: 2px solid $color-royal;
    }
    & .link-see-more {
      border-color: $color-royal;
      color: $color-royal;
    }
    & .btn-primary {
      background-color: $color-royal;
      border-color: $color-royal;
    }
  }

  &--the-reserve &__content-room {
    border-color: $color-the-reserve;
    &:before {
      width: 140px;
      height: 85px;
      background-position: 45% 45%;
      background-image: url("https://www1.melia.com/img/brand-logos/Paradisus_TheReserve.svg");
      background-size: 65%;
      border: 2px solid $color-the-reserve;
    }
    & .link-see-more {
      border-color: $color-the-reserve;
      color: $color-the-reserve;
    }
    & .btn-primary {
      background-color: $color-the-reserve;
      border-color: $color-the-reserve;
    }
  }
}

html[dir="rtl"] .c-hotel-sheet-room__content-room:before {
  left: auto;
  right: -10px;
}

@media (max-width: $screen-sm-max) {
  .c-hotel-sheet-room {
    &--level &__content-room,
    &--redlevel &__content-room,
    &--me &__content-room,
    &--family &__content-room,
    &--royal &__content-room {
      .link-see-more {
        float: right;
      }
    }
  }
}

@media (max-width: $screen-xs-max) {
  .c-hotel-sheet-room {
    &__content-room,
    &__content-image,
    &__content-content {
      width: 100%;
      display: block;
    }

    &__content-image {
      min-height: 200px;
    }

    &__content-button {
      padding-top: 15px;
      > .btn,
      > .link-see-more {
        width: 100%;
      }
    }

    .link-see-more {
      display: block;
      text-align: left;
      margin-bottom: 15px;
      margin-top: 5px;
    }
  }
}
