//Title hotel sheet intro with logo
.c-hotel-sheet-map {
  &__container {
    position: relative;
  }

  &__gmap {
    width: 100%;
    height: 550px;
    overflow: hidden;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__details {
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 20px;
    margin-left: 5px;
    background-color: rgba(255, 255, 255, 0.85);
    font-family: "Lato", sans-serif;
    font-size: 14px;
    color: $color-dark-gray;
    width: 300px;
  }

  &__toggler-btn {
    position: absolute;
    right: 0;
    top: 0;
    padding: 15px;
    appearance: none;
    background: transparent;
    border: none;
    outline: none;
    transform: rotate(0deg);
    transition: transform 0.3333s linear;
  }

  &__title {
    margin: 0 0 10px 0;
    font-size: 18px;
    text-transform: uppercase;
  }

  &__address {
    margin: 0;
    font-size: 14px;
    line-height: 1.5;

    > p:first-child {
      margin-bottom: 5px;
    }

    > p:last-child {
      margin-bottom: 0;
    }
  }

  &__toggler {
    margin-top: 15px;
    overflow: hidden;
    max-height: 0;
    transition: ease-in-out 0.3333s max-height;
    margin-bottom: -15px;
  }

  dt:after {
    content: ":";
    margin-right: 5px;
  }
  &__coordinates {
    font-weight: 700;
    text-transform: uppercase;
    float: inherit;
    margin-top: 25px;
  }

  &__print {
    display: block;
    margin-bottom: 20px;

    > i {
      margin-right: 5px;
    }
  }
  &__btn {
    vertical-align: top;
  }

  &__title h5 {
    margin: 0 0 10px 0;
    font-size: 18px;
    text-transform: uppercase;
  }
  dt:after {
    content: ":";
    margin-right: 5px;
  }

  &--open &__toggler {
    max-height: 400px;
  }

  &--open &__toggler-btn {
    transform: rotate(180deg);
  }
}

@import "../../components/c-hotel-sheet-map-hotel/c-hotel-sheet-map-hotel";
