// IE10 viewport hack for Surface/desktop Windows 8 bug
@-ms-viewport {
  width: device-width;
}
@-o-viewport {
  width: device-width;
}
@viewport {
  width: device-width;
}

// Colors
// Melia
$brand-primary: #0075c0;
$color-base:$brand-primary;
$brand-primary-hover: #286090;
$color-borders: #c4c4c4;
$color-black: #333333;
$color-dark-gray: #888888;
$color-darker-gray-plus:    #3C3D3E;
$color-gray: #dddddd;
$color-soft-gray: #9b9b9b;
$color-light-gray: #f2f2f2;
$color-lighter-gray:$color-light-gray;
$color-white: #ffffff;
$brand-primary-qr: #545454;
$brand-secondary-qr: #cdb9ac;
$color-gift:#d4b98a;
//REWARDS
$brand-success--rwr: #a5da70;
//#9ad6ad
$brand-warning--rwr: #ded671;
$brand-danger--rwr:  #db6d7a;
$brand-info--rwr:    #72a4de;
$steps-color:#9ad6ad;
$highlight-checkin-color:$steps-color;
// States
$color-yellow: #face22;
$color-sucess: #72e0cc;
$color-danger: #ff2440;
$color-discount: #fc4c02;
$color-perso: #02c498;
$color-border-gray: $color-light-gray;
$color-boosters: #30bd9c;

// Marcas habitaciones superiores
$color-level: #626971;
$color-redlevel: #9b875a;
$color-me: #252525;
$color-me-secondary: #8ea5af;
$color-royal: #f8cc6b;
$color-family: #7ea8ad;
$color-level-bg: #d5d6d8;
$color-redlevel-bg: #d3d3d3;
$color-me-bg: #252525;
$color-royal-bg: #979797;
$color-family-bg: #979797;
$color-the-reserve:  #5b2b82;

// Colores para aceleradores
$color-red: #dd0000;
$color-green: #09a304;

// Colores para btn sociales
$color-twitter: #87d3e2;
$color-twitter-hover: #1da1f2;
$color-facebook: #4267b2;
$color-facebook-hover: #29487d;
$color-google: #dd4b39;
$color-google-hover: #e64c39;

// Restaurants
$color-amaro-primary: #00afa9;
$color-amaro-secondary: #efe2ce;
$color-amaro-default: #ae8e41;

// Buttons
$btn-default-bg-hover: #286090;
$btn-default-border-hover: #286090;

// Breadcrumbs
$breadcrumbs-gray: #424242;

// Font
$font-family-lato-regular: "Lato-Regular", sans-serif;

// Box radius
$box-radius: 4px;

// Media Queries
$screen-xxs-max: 479px;
$screen-xs-min: 480px;
$screen-xs-max: 767px;
$screen-sm-min: 768px;
$screen-sm-max: 991px;
$screen-md-min: 992px;
$screen-md-max: 1199px;
$screen-lg-min: 1200px;
