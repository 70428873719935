.c-hotel-sheet-buttons-top {
  overflow: auto;

  &__container {
    margin: 15px 0;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }

  &__arrows {
    position: sticky;
    height: 44px;
    width: 36px;
    display: inline-block;
    vertical-align: middle;
    color: $color-dark-gray;
    background-color: $color-light-gray;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &:first-child { left: 0 }
    &:last-child { right: 0 }
  }

  &--left &__arrows:first-child,
  &--right &__arrows:last-child { color: $color-black }
}

@media (min-width: $screen-sm-min) {
  .c-hotel-sheet-buttons-top {
    &__arrows { display: none }
    &__container { 
      margin: 30px 0;
      width: auto;
    }
  } 
}