.c-room-more-info {
    display: block !important;
    transition: .33s ease all;
    background-color: rgba(0, 0, 0, .5);
    opacity: 0;
    padding-top: 50px;
    visibility: hidden;
    &__content {
        margin: 30px auto;
        height: calc(100vh - 60px);
        overflow-x: hidden;
        overflow-y: auto;
        // El tema actual tiene un sizing diferente. El de abajo es el de Melia.com
        @media (min-width: 768px) { width: 600px !important; }
        @media (min-width: 992px) { width: 750px !important; }
        @media (min-width: 1200px) { width: 900px !important; }
        
    }
    th, td {
        font-weight: 300;
        color: #333;
        text-align: center;
        padding: 10px !important;
        border: 2px solid white !important;
    }

    tbody > tr > th:first-child {
        text-align: right;
    }

    tbody > tr > th:not(:first-child) {
        font-size: 20px;
    }

    tbody > tr > th > .line {
        &::before {
            content: "\2014" ;
        }
    }

    tbody > tr > th > .icon-tick {
        color: green;
    }

    &__footer {
        text-align: center;
        > li {
            display: inline-block;
            padding: 8px 5px
        }
    }

    &--visible {
        display: block !important;
        padding-top: 0;
        opacity: 1;
        visibility: visible;
    }
}