.c-hotel-sheet-img-text {
    .grid-item {
        position: relative;
        margin: 5px -5px 5px -5px;
        .image {
            position: relative;
            background-size: cover;
            background-position: center;
            overflow: hidden;
            height: 300px;
            .caption {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                height: 100%;
                z-index: 0;
                .caption-inner {
                    border-color: transparent;
                    height: 100%;
                    .middle-content {
                        position: absolute;
                        width: 100%;
                        top: 50% !important;
                        left: 0;
                        .visible-hover {
                            visibility: hidden;
                            padding: 0 20px;
                            font-family: 'lato', sans-serif;
                            font-size: 16px;
                            font-weight: 400;
                            color: $color-white;
                            text-shadow: none;
                        }
                    }
                }
            }
            .no-color {
                background: transparent;
                -webkit-transition: all .5s;
                transition: all .5s;
            }
        }
        &:hover, &:focus, &:active {
            .no-color {
                background: rgba(0, 0, 0, 0.5);
                .caption-inner {
                    .middle-content {
                        .visible-hover {
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
    .half {
        margin-bottom: 10px;
        .image {
            height: 145px;
        }
    }
    .all-link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    .btn-footer {
        clear: both;
        margin: 30px 0px;
        text-align: center;
    }
}

@media (max-width: $screen-xs-max) {
    .c-hotel-sheet-img-text {
        .btn-footer { margin: 30px 10px; }
        .btn-footer > .btn { width: 100% }
    }
}