//Title hotel sheet intro with logo
.c-hotel-sheet-hotels {
  margin-bottom: 30px;
  margin-left: 5px;
  margin-right: 5px;

  &__link {
    color: initial;
    display: block;
  }

  &__hotel {
    margin: 10px 0;
    position: relative;
    min-height: 470px;
    &--image {
      position: relative;
      height: 220px;
      background-size: cover;
      background-position: center;
      overflow: hidden;
      img {
        height: 220px;
        -o-object-fit: cover;
        object-fit: cover;
        font-family: "object-fit: cover;";
        width: 100%;
      }
      .caption {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.25);
        z-index: 1;
        .caption-inner {
          height: 100%;
          .middle-content {
            position: absolute;
            margin-top: -16.5px;
            width: 100%;
            top: 50% !important;
            left: 0;
            h3 {
              margin: 0;
              color: $color-white;
              font-size: 30px;
              font-weight: 700;
              text-align: center;
            }
          }
        }
      }
    }

    &--footer {
      padding: 10px;
      background-color: $color-white;
      text-align: center;
      h5 {
        padding: 11px 0;
        margin: 0 0 8px;
        border-bottom: 1px solid $color-gray;
        font-size: 17px;
        font-weight: 700;
        text-transform: none;
        min-height: 70px;
        p {
          margin-bottom: 0;
        }
      }
      .offer {
        padding: 4px 0;
        min-height: 50px;
        border-bottom: 1px solid $color-gray;
        color: $color-black;

        .from {
          margin-right: 5px;
          margin-bottom: 3px;
          text-transform: uppercase;
        }
        .price {
          font-size: 29px;
          font-weight: 600;
          line-height: 1.25;
        }
        .pax {
          display: inline-block;
          text-align: left;
          line-height: 0.9;
          span {
            display: block;
          }
        }
      }
      button {
        margin-top: 20px;
        margin-bottom: 10px;
      }
    }

    &--all-link {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &:hover .caption {
      padding: 7px;
      background-color: transparent;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
    }
  }

  .btn-container {
    margin: 20px auto;
    text-align: center;
  }
}

@media (max-width: $screen-sm-max) {
  .c-hotel-sheet-hotels {
    .btn {
      width: 100%;
    }
  }
}


html[dir="rtl"] .c-hotel-sheet-hotels__hotel .offer .from{
  display: inline-block !important;
}
