.c-credit-resort {
    position: fixed;
    right: 0;
    bottom: 0;
    background-color: $color-white;
    padding: 30px;
    border: 2px solid $brand-primary;
    z-index: 1000;
    font-size: 1.3em;
    display: none;
    width: 450px;
    .icon-present {
        position: absolute;
        color: $brand-primary;
        font-size: 18px;
    }
    &__content {
        margin-left: 25px;
    }
    &__text, a {
        margin-bottom: 0;
        min-height: 25px;
        font-size: 15px;
        text-decoration: none;
    }
    a {
        color: $brand-primary;
    }
    .icon-close {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
        color: $brand-primary;
    }
}
#modalCreditResort .modal-content-body {
    padding: 25px;
}