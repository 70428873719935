/************ POPUP ULTIMAS BUSQUEDAS ****************/
@media (min-width: 1025px) {
    
    .c-last-search-select{
        top: -15px;
        display:block !important;
        position: relative;
        -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
        .c-last-search-popup-new {
            bottom:unset;
            box-shadow:none;
            -moz-box-shadow:none;
            -wekit-box-shadow:none;
            -o-box-shadow:none;
            width: 250px !important;
            top:0;
            .formholder{
                background:none !important;
                padding:0 !important;
                .randompad{
                    background:none;
                    padding-bottom:0px !important;
                    ul{
                        -webkit-box-shadow: 0 2px 2px 0 rgba(51,51,51,.6);
                        -moz-box-shadow: 0 2px 2px 0 rgba(51,51,51,.6);
                        box-shadow: 0 2px 2px 0 rgba(51,51,51,.6);
                        background-color:$color-white;
                        width: 410px !important;
                        border:none !important;
                        padding-top:0px !important;
                        li{
                            padding:0 !important;
                            &:hover{padding:0 !important;}
                            a{
                                padding:15px 20px !important;
                            }
                        }
                        
                    }
                }
                &:hover{
                    .randompad{
                        background-color:$color-white !important;
                        .c-last-search-popup-new__header {
                            span, & >*{
                                color:$color-black !important;

                            }
                        }
                    }
                    
                    .c-last-search-popup-new__header span:after{
                        display:inline-block;
                        -webkit-transform: rotate(180deg);     /* Chrome and other webkit browsers */
                        -moz-transform: rotate(180deg);        /* FF */
                        -o-transform: rotate(180deg);          /* Opera */
                        -ms-transform: rotate(180deg);         /* IE9 */
                        transform: rotate(180deg);    
                    }
                    ul{
                        display:block !important;
                    }
                }
                
            }
            
            
            .c-last-search-popup-new__header {
                cursor:pointer;
                margin-bottom: 0px;
                height: 65px;
                vertical-align: middle;
                display: table-cell;
                width: 250px !important;
                position:relative;
                & > *{
                    color:$color-white !important;
                }
                span.name{
                    padding-right:10px;
                    color:$color-white !important;
                    &:after{
                        font-family:"icons_melia_common" !important;
                        content:"\e901";
                        position:absolute;
                        right:10px;
                        bottom:0;
                        height:10px;
                        top:0;
                        margin:auto;
                    }
                }
            }
        }
        
        & + .mbe-destination.bannerMBE {
            .mbe-input-destination{
                display:none;
            }
            position:relative;
            .bookFromOnly{
                position:absolute;
                right:20px;
                text-align: center;
                display:block !important;

            }
        }
    }
}
/************FIN POPUP ULTIMAS BUSQUEDAS ****************/


