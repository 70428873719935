.o-module {
    .container{
        padding: 0;
        .c-hotel-sheet-intro-service{
            margin-bottom: 25px;
            padding: 0;
            .c-hotel-sheet-services-includes-blank{
                width: calc(25% - 1ch);
                padding-top: 30px;
                text-align: center;
                display: inline-block;
                vertical-align: middle;
            }
            .c-hotel-sheet-services-includes {
                background-color: #F6FAF5;
                width: 75%;
                padding: 30px 0 0 0;
                height: 100%;
                display: inline-block;
                vertical-align: middle;
                .services {
                    width: 100%;
                    padding: 0 0 10px 20px;
                    p.services-title {
                        font-size: 16px;
                        text-transform: uppercase;
                        color: $color-green;
                        font-weight: bold;
                        text-shadow: none;
                        i.icon-info-mini {
                            color: $brand-primary;
                            position: relative;
                            margin-left: 10px;
                            font-size: 14px;
                        }
                    }
                    ul {
                        display: table;
                        width: 100%;
                        li {
                            width: 100%;
                            font-weight: bold;
                            input[type="checkbox"]{
                                width: 20px;
                                height: 20px;
                                position: relative;
                                top: -2px;
                                left: -10px;
                                margin-right: 5px;
                            }
                            span {
                                color: $color-white;
                                text-transform: uppercase;
                                text-align: center;
                                margin: 5px 10px 5px 0;
                                padding: 2px 5px;
                                display: inline-block;
                                width: 75px;
                                font-weight: 300;
                            }
                            .free, .include{
                                background-color: $color-green;
                            }
                            .optional{
                                background-color: $brand-primary;
                            }
                            p{
                                width: 65%;
                                display: inline-table;
                                margin: 5px 0;
                            }
                        }
                    }
                }
            }
        }
    }


}

@media (max-width: 768px){
    .c-hotel-sheet-services-includes-blank{
        display: none !important;
    }
    .c-hotel-sheet-services-includes{
        width: 100% !important;
    }
}