//Title hotel sheet intro with logo
.c-hotel-sheet-description {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 40px;
    &__text {
        ul li {
            padding-bottom: 10px;
            padding-right: 20px;
            padding-left: 15px;
            position: relative;
            line-height: 1.25;
        }
        ul li:before {
            content: "·";
            color: $color-gray;
            position: absolute;
            left: 0;
            font-size: 35px;
            line-height: 0.378;
        }
        ul li + li {
            margin-top: 10px;
        }
        p {
            margin:  0 0 10px;
            line-height: 1.4;
        }
    }
    &__trip-address {
        margin-top: 20px;
        article {
            padding-bottom: 15px;
            .tripadvisor-stuff {
                margin-bottom: 5px;
                .tripadvisor-bullets {
                    width: 76px;
                    float: left;
                    margin-top: 8px;
                    margin-right: 10px;
                }
                span {
                    margin-top: 8px;
                    display: inline-block;
                }
            }
            address {
                margin: 10px 0;
                font-size: 14px;
                font-style: normal;
                line-height: 1.42857143;
            }
        }
        article + article {
            padding-top: 18px;
            border-top: 1px solid $color-gray;
        }
    }
}
@media (min-width: 992px) {
    .c-hotel-sheet-description {
        &__trip-address {
            border-left: 1px solid $color-gray;
            padding-left: 15px;
            padding-right: 15px;
            margin-top: 0;
        }
    }
}

html[dir="rtl"] .c-hotel-sheet-description ul li:before {
    left: auto;
    right: 0;
}
html[dir="rtl"] .c-hotel-sheet-description__text .more-container {
	padding-right: 15px;
}

//banner v + h
.banner-flight-hotel {
    background: url(http://newweb.melia.com/assets/img/banner-flight-hotel.jpg);
    width: 366px;
    height: 352px;
    position: relative;
    display: table-cell;
    vertical-align: bottom;
    text-align: center;
    border-top: 6px solid #072244;
}
.copy-flight-hotel {
    /* display: table-cell; */
    width: 100%;
    position: absolute;
    top: 10px;
    text-align: center;
    color: white;
    max-width: 319px;
    /* font-weight: bold; */
    top: 60px;
    font-size: 30px;
    text-transform: uppercase;
    padding: 0 32px;
    line-height: 34px;
    width: 100%;
    text-align: center;
    margin: auto;
    left: 0;
    right: 0;
}
a.btn.btn-primary.book-flight-hotel {
    text-align: center;
    margin-bottom: 25px;
    min-width: 200px;
    line-height: 23px;
    border:none;
}


.banner-flight-hotel-fixed {
    width: 780px;
    height: 104px;
    background: url(https://newweb.melia.com/assets/img/banner-flight-hotel-hor.jpg);
    position: fixed;
    bottom: 19px;
    left: 10px;
    z-index: 9999;
    display: table;
}

.banner-flight-hotel-fixed > * {
    display: table-cell;
    vertical-align: middle;
}
.banner-flight-hotel-fixed .copy-banner-flight-hotel-fixed{
    width: 60%;
    text-align: center;
    color: white;
    font-size: 24px;
    text-transform: uppercase;
}
.banner-flight-hotel-fixed .button-banner-flight-hotel-fixed {
    width: 40%;
    text-align: left;
}
.banner-flight-hotel-fixed .button-banner-flight-hotel-fixed a{
    min-width: 80%;
    text-align: center;
    border:none;

}
.banner-flight-hotel-fixed a.close {
    position: absolute;
    top: 10px;
    right: 8px;
    color: #ffffff;
    font-size: 13px;
}



/*banner buscador*/

.banner-flight-hotel-search {
    background: url(https://www1.melia.com/testAB/banner-buscador.jpg);
    width: calc(100% - 30px);
    max-width: 600px;
    text-align: center;
    margin: auto;
    position: relative;
    height: 134px;
    display: table;
    object-fit: cover;
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    margin-left: 0 15px !important;
    margin-top:20px;
}
.copy-banner-flight-hotel-search {
    display: table-row;
    vertical-align: middle;
    height: 50%;
}
.copy-banner-flight-hotel-search span {
    display: table-cell;
    vertical-align: middle;
    color: white;
    font-size: 21px;
    text-transform: uppercase;
}
.button-banner-flight-hotel-search {
    display: table-row;
    vertical-align: middle;
}
.button-banner-flight-hotel-search a {
    display: inline-block;
    border:none;
}



.banner-flight-hotel-search.insideSearchBlock {
    margin-top:120px;
}
.button-flight-hotel{
    background: #0075c0;
    color: #fff;
    width: 100%;
    border: none;
    height: 45px;
    margin: 0 auto;
    width: auto;
    vertical-align: middle;
}
.button-flight-hotel span {
    vertical-align: middle;
    display: inline-block;
    line-height: 42px;
    text-transform: uppercase;
    padding: 0px 20px;
}
@media (max-width:767px){
    .c-hotel-sheet-description {
        margin: 25px;
    }
}