html {
  position: relative;
  min-height: 100%;
}

dl {
    margin-top: 0;
    margin-bottom: 20px;
}

dt {
    line-height: 1.5;
    float: left;
    font-weight: normal;
}
  
dd {
    line-height: 1.5;
    margin-left: 0;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

h1, h2, h3 {
    text-transform: uppercase;
}

a {
    cursor: pointer;
}

a:hover {
    text-decoration: none;
}

p { line-height: 1.4; }


// Disable image
.disable-click {
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    -o-user-select: none;
    user-select: none;
}