//Title hotel sheet intro with logo
.c-hotel-sheet-share {

    &.u-social-icons { margin: 0; }

    &__first {
        float: left;
        margin: 30px 0 25px;
        padding: 22px;
        lh {
            display: inline-block;
            font-weight: 700;
            margin-right: 20px;
            float: left;
            margin-top: 10px;
            text-transform: uppercase;
            color: $color-dark-gray;
        }
        li {
            float: left;
            margin-right: 8px;
            a {
                font-size: 35px;
                text-decoration: none;
                i.icon-twitter-circle, i.icon-facebook-circle, i.icon-googleplus-circle {
                    color: $color-dark-gray;
                    font-size: 35px;
                    transition: color .3s;
                    -webkit-transition: .3s;
                }
            }
        }
    }
    &__second {
        float: right;
        margin: 30px 0 25px;
        padding: 22px;
        li {
            float: left;
            margin-top: 10px;
            font-weight: 700;
            text-transform: uppercase;
            color: $color-dark-gray;
            a {
                float: right;
                margin: -11px 10px 0 10px;
                font-size: 35px;
                text-decoration: none;
                i.icon-send-circle, i.icon-print-circle {
                    color: $color-dark-gray;
                    font-size: 35px;
                    transition: color .3s;
                    -webkit-transition: .3s;
                }
            }
        }
        li.send {
            margin-right: 50px;
        }
    }
}
@media (max-width:767px){
    .c-hotel-sheet-share {
        ul{
            padding-left: 25px!important;
            li{
                padding-left:0px !important;
            }
        }
        &__first{
            margin-bottom:0px;
            padding-bottom: 0px;
        }
        &__second {
            margin-top:0px;
            padding-top:0px;

            li{
                margin-top:30px;
            }
        }
    }
  }