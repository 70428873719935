.c-hotel-sheet-budget-request {
    position: relative;
    margin: 40px 0;
    padding: 20px 20px 40px 20px;
    background-color: $color-white;
    border: 1px solid $color-gray;
    text-align: center;
    font-size: 15px;
    &__btn {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -20px;
    }
}