//Hotel images preview
.c-hotel-sheet-images {
  &__photos {
    height: 679px;
    padding: 2.5px;
  }

  &__photo {
    position: relative;
    float: left;
    overflow: hidden;
    border: 2.5px solid transparent;
  }

  &__photo-link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.35);
    opacity: 0;
    transition: opacity 0.3333s ease;

    &:hover {
      opacity: 1;
    }
  }

  &__photo-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__photo-icon {
    color: #fff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    font-size: 33px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__buttons {
    i {
      margin-left: 5px;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .c-hotel-sheet-images {
    &__photo {
      height: 50%;
      &:first-child {
        width: 100%;
      }

      &:nth-child(2),
      &:nth-child(3) {
        width: 50%;
      }

      &:nth-child(n + 4) {
        display: none;
      }
    }

    &__buttons {
      .btn {
        margin: 5px;
        display: block;
      }

      i {
        float: right;
      }

      .icon-photo-360 {
        font-size: 20px;
        margin-right: -7px;
      }

      .icon-camera {
        font-size: 18px;
      }

      .icon-video {
        font-size: 24px;
      }
    }
  }
}

@media (min-width: $screen-sm-min) {
  .c-hotel-sheet-images {
    &__buttons {
      float: left;
      width: 100%;
      display: flex;
      justify-content: center;
      padding-bottom: 50px;
      padding-top: 15px;

      .btn {
        display: inline-flex;
        align-items: center;
        padding: 10px 20px;
        margin: 0 2.5px;
      }

      .icon-photo-360 {
        font-size: 20px;
        margin-right: -7px;
      }

      .icon-camera,
      .icon-video {
        margin-top: -3px;
      }

      .icon-camera {
        font-size: 19px;
      }

      .icon-video {
        font-size: 23px;
      }
    }

    &--4 &__photo {
      width: 33.33333%;
      height: 33.33333%;

      &:nth-child(1) {
        width: 66.66666%;
        height: 66.6666%;
      }
      &:nth-child(2) {
        height: 100%;
        float: right;
      }
    }

    &--6 &__photo {
      height: 33.3333%;
      width: 25%;

      &:nth-child(1),
      &:nth-child(4) {
        height: 66.66666%;
        width: 50%;
      }

      &:nth-child(4) {
        float: right;
      }
    }
  }
}
