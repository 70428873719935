//Restaurant and services hotel sheet info
.c-hotel-sheet-info-description {
  border-bottom: 1px solid $color-light-gray;
  padding-bottom: 40px;
  margin-top: 40px;
  .description-foto {
    position: relative;
    img {
      width: 100%;
    }
    .media-icon {
      position: absolute;
      right: 20px;
      bottom: 10px;
      padding: 10px;
      font-size: 12px;
      background-color: $color-black;
      i {
        font-size: 15px;
        display: inline-block;
        top: 2px;
        color: $color-white;
      }
    }
  }
  .description-content {
    margin-top: 15px;
    h4 {
      font-size: 26px;
      margin-top: 0;
      margin-bottom: 20px;
      text-transform: uppercase;
    }
    p {
      line-height: 1.4;
    }
    .btn {
      margin-top: 20px;
      width: 100%;
    }
    ul {
      margin-bottom: 10px;
      padding-left: 0;
      font-size: 15px;
      li {
        padding-bottom: 5px;
        padding-right: 20px;
        padding-left: 15px;
        position: relative;
        list-style: none;
        line-height: 1.25;
      }
      li + li {
        margin-top: 10px;
      }
      li:before {
        content: "·";
        color: $color-gray;
        position: absolute;
        left: 0;
        font-size: 35px;
        line-height: 0.378;
      }
    }
    .more-trigger {
      font-size: 15px;
      padding-left: 10px;
    }
  }
}

html[dir="rtl"] .c-hotel-sheet-info-description .description-content ul li:before {
    left: auto;
    right: 0;
}

@media (min-width: $screen-md-min) {
  .c-hotel-sheet-info-description {
    overflow: auto;
    .description-foto {
      width: 50%;
      float: left;
      padding-left: 10px;
      padding-right: 10px;
    }
    .right {
      float: right;
    }
    .description-content {
      width: 50%;
      float: left;
      margin-top: 0;
      padding-left: 50px;
      font-size: 15px;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .c-hotel-sheet-info-description {
    .description-foto,
    .description-content {
      width:  100%;
      float: none;
    }
  }
}
@media (max-width:767px){
  .c-hotel-sheet-info-description  {
      margin: 25px;
  }
}