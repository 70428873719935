//Tripadvisor reviews content
.c-hotel-sheet-reviews {
  &__header {
    font-family: Arial;
    font-size: 14px;
    color: $color-dark-gray;
    margin-top: 70px;
  }

  &__header-rating {
    display: inline-block;
    line-height: 37px;
    img {
      height: 19px;
    }
  }

  &__header-init {
    display: inline-block;
    float: right;
    font-size: 12px;
    img {
      height: 37px;
      position: relative;
      top: -2px;
    }
  }

  &__header-title {
    font-family: Arial;
    font-size: 20px;
    color: $color-black;
    margin-top: 41px;
    text-transform: none;
  }

  &__reviews {
    width: 100%;
    clear: both;
    border-top: 1px solid $color-gray;
    font-family: "Arial", sans-serif;
  }
  &__reviews-name {
    width: 35%;
    display: inline-block;
    float: left;
    padding-top: 22px;
    strong {
      font-size: 14px;
      color: $color-black;
    }
  }

  &__reviews-review {
    width: 65%;
    display: inline-block;
    float: left;
    clear: right;
  }

  &__reviews-title h6 {
    padding-top: 16px;
    margin-top: 0;
    margin-bottom: 5px;
    font-family: Georgia;
    font-style: italic;
    font-size: 18px;
    color: $color-black;
    text-transform: capitalize;
    line-height: 1.4;
  }

  &__reviews-stars {
    display: block;
    margin: 0 0 10px;
    font-size: 14px;
    color: $color-dark-gray;
    img {
      height: 14px;
    }
  }

  &__reviews-comment {
    font-size: 14px;
    color: $color-dark-gray;
    margin: 16px;
    margin-left: 0;
    line-height: 1.5;
  }

  &__footer {
    width: 100%;
    clear: both;
    padding-top: 12px;
    border-top: 1px solid $color-gray;
    font-family: "Arial", sans-serif;
  }

  &__footer-total {
    display: block;
    margin-bottom: 16px;
    font-size: 14px;
    color: $color-dark-gray;
  }

  &__footer-sub {
    font-size: 11px;
    color: $color-dark-gray;
    margin: 0 0 5px;
  }

  &__button {
    width: 100%;
    text-align: center;
    button {
      margin: 50px 0;
    }
  }

  .modal-content {
    margin: 30px auto;
    .modal-body {
      .c-hotel-sheet-reviews__header {
        margin-top: 20px;
      }
      position: relative;
      padding: 15px;
      max-height: 600px;
      overflow: hidden;
      overflow-y: scroll;
      .opiniones-footer-modal {
        clear: both;
        border-top: 1px solid $color-gray;
        font-weight: bold;
        font-family: "lato", sans-serif;
        text-align: center;
        padding: 50px 0 40px 0;
        font-size: 14px;
      }
    }
  }
}

@media (max-width: $screen-xs-max) {
  .c-hotel-sheet-reviews {
    &__header {
      margin-top: 30px;
      text-align: center;
    }

    &__header-rating {
      display: block;
    }

    &__header-init {
      display: inline-block;
      float: none;
      font-size: 0;
      margin: 0 auto;
    }

    &__header-title {
      margin-top: 30px;
      text-align: left;
    }

    &__reviews-name,
    &__reviews-review {
      display: block;
      width: 100%;
      float: none;
    }

    &__reviews-review {
      margin-left: 15px;
    }
  }
}
@media (max-width:767px){
  .c-hotel-sheet-reviews {
      margin: 25px;
  }
}