.c-hotel-sheet-services-includes {
  padding: 15px;
  margin: 30px 0;
  background: white;

  &__list-wrap {
    position: relative;
  }

  &__list {
    overflow: hidden;
  }

  &__heading {
    display: flex;
    align-items: center;
  }

  &__graphic-wrap {
    flex-shrink: 0;
  }
  &__present {
    font-size: 33px;
    position: relative;
    top: 3px;
    margin-right: 2px;
    color: $brand-primary;
  }

  &__free {
    margin-left: -5px;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: bold;
    font-smoothing: antialiased;
    margin: 0;
    margin-right: 10px;
    display: inline-block;
    color: $brand-primary;
  }

  &__heading-title {
    margin: 0;
    padding-top: 15px;
    padding-right: 15px;
  }

  &__item,
  &__title {
    margin-bottom: 0;
  }

  &__title {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    padding-right: 15px;
  }

  &__item {
    width: calc(33.333333% - 30px);
    display: inline-flex;
    position: relative;
    z-index: 2;
    border-radius: 0;
    padding: 15px;
    padding-right: 30px;
    margin: 0 5px;
    cursor: pointer;
    color: $color-black;
    outline: none;
    text-align: left;
    background-color: transparent;
    border-bottom: 2px solid $color-borders;

    &.active {
      border-bottom-color: $brand-primary;
    }
  }

  &__tabpanel {
    padding: 0;
  }

  &__tabpanel-content {
    overflow: hidden;
  }

  &__check,
  &__open {
    &::before {
      display: block;
      position: relative;
      top: 50%;
    }
  }

  &__check {
    font-size: 13px;
    padding-right: 10px;
    color: $brand-primary;
    position: relative;
    &::before {
      transform: translateY(-50%);
    }
  }

  &__open {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0 10px;
    color: $color-borders;

    &::before {
      transform: translateY(-50%) rotate(45deg);
      transition: all ease 0.33333s;
    }

    .active &::before {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &__list-description {
    padding: 0;
  }

  &__expiration-date {
    display: block;
    margin-top: 15px;
    text-align: right;
  }

  //if Slick (overrides mostly):
  .slick-slider {
    padding-bottom: 17px;
  }

  .slick-track {
    display: flex;
  }

  i.slick-arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -10px;
    font-size: 22px;
    padding: 0 6px;
    color: $breadcrumbs-gray;

    &.icon-right-arrow {
      left: auto;
      right: -10px;
    }

    &.slick-disabled {
      color: $color-borders;
    }

    &::before {
      position: relative;
      top: calc(50% - 7.5px);
      transform: translateY(-50%);
      display: block;
    }
  }

  .slick-dots {
    margin-top: 10px;
    text-align: center;

    > li {
      display: inline;
      cursor: pointer;
    }

    > li > button {
      font-size: 0;
      background-color: $color-soft-gray;
      padding: 3px;
      border-radius: 50%;
      margin: 5px 13px;
      border: none;
      outline: none;
    }

    > li.slick-active > button {
      transform: scale(1.65);
      background-color: $brand-primary;
    }
  }

  &--slick &__tabpanel-content {
    margin-bottom: 10px;
  }

  &--slick .slick-slider {
    margin: 0 25px;
  }

  //In case SLICK is NOT used
  &:not(#{&}--slick) &__list {
    display: flex;
    justify-content: center;
    margin: 0;
    margin-bottom: 15px;
    overflow: visible;
  }

  &:not(#{&}--slick) &__item {
    display: flex;
    margin: 0;
    flex-grow: 1;

    &:not(:last-child) {
      margin-right: 30px;
    }
  }

  &:not(#{&}--slick) &__tabpanel {
    margin: 0;
  }

  &:not(#{&}--slick) &__tabpanel-content {
    padding: 0;
  }
}
@media (max-width:767px){
  .c-hotel-sheet-services-includes {
    &__list-wrap {
      margin-top:10px;
    }
      //In case SLICK is NOT used
    &:not(#{&}--slick) &__list {
      display: block;
    }

    &__item {
      width: 100%;
    }

  }
}