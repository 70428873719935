//Title hotel sheet intro with logo
.c-hotel-sheet-rewards-promo {
    padding: 8px 0;
    margin-bottom: 0;
    text-align: center;
    color: $color-black;
    font-size: 15px;
    border-bottom: 1px solid $color-light-gray;
    div {
        display: inline-block;
        strong {
            display: inline-block;
            height: 40px;
            width: 40px;
            margin: 0 10px;
            -webkit-border-radius: 80px;
            -moz-border-radius: 80px;
            border-radius: 80px;
            font-weight: normal;
            color: $color-white;
            font-family: $font-family-lato-regular;
            background-color: $brand-primary;
            .discount {
                display: block;
                margin-top: 12px;
                font-size: 16px;
                font-weight: normal;
                text-align: center;
                span {
                    font-size: 12px;
                }
            }
        }
    }
    p {
        display: inline-block;
        text-transform: uppercase;
    }
}
@media(max-width: 991px) {
    .c-hotel-sheet-rewards-promo {
        div {
            display: block;
        }
        p {
            margin-top: 10px;
        }
    }
}