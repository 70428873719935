//Title hotel sheet intro with logo
.c-hotel-sheet-navigation {
  position: relative;
  border-bottom-width: 5px;
  border-bottom-style: solid;

  .container {
    padding: 0;
  }

  > .container {
    display: flex;
  }

  .row {
    margin: 0;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    display: flex;
    flex-grow: 1;
  }

  &__list-item {
    width: 22%;
    margin-bottom: 5px;
    float: left;
    background-color: $color-light-gray;
    transition: background-color 0.3s;
    flex: 1 1;

    &:first-child {
      border-left: 0;
    }

    &:not(:first-child) {
      display: none;
      margin-left: 5px;
    }

    &:hover {
      background-color: $color-white;

      a {
        text-decoration: none;
      }
    }

    &--active {
      /*  Algunos engines de rendering marcan una separacion del background. 
          Esto es un workaround temporal, solo estético, si causa conflictos 
          funcionales eliminar sin miedo. (El Margin: -1)
      */
      margin-bottom: -1px;
      padding-bottom: 5px;

      a {
        color: $color-white !important;
        font-weight: 700;
        border-top: 0;
      }
    }
  }

  &__list-link {
    display: table;
    width: 100%;
    color: $color-black;
    transition: color 0.3s;
    text-decoration: none;
  }

  &__list-text {
    display: table-cell;
    vertical-align: middle;
    height: 55px;
    text-align: center;
  }

  &__list-menu {
    width: 70px;
    float: left;
    font-size: 18px;
    margin: 0 0 5px 5px;
    display: table;
    color: $color-black;
    background-color: $color-light-gray;
    transition: background-color 0.3s;

    &:hover,
    .open & {
      background-color: $color-white;
      text-decoration: none;
    }
  }

  &__dropdown {
    left: 50%;
    transform: translateX(-50%);
    z-index: 700;
    padding: 0;
    border: none;
    box-shadow: none;
    background-color: transparent;

    //pisa el espacio blanco entre el borde azul y los contenedores grises.
    top: calc(100% + 5px);
  }

  &__dropdown-item {
    margin: -5px 0;
    background-color: $color-light-gray;
    text-align: center;
    border: 5px solid;

    &:first-child {
      display: none;
    }
  }

  &__dropdown-link {
    padding: 0 !important;
    text-transform: uppercase;
    text-decoration: none;
    display: table !important;
    width: 100%;

    &:nth-child(1) {
      margin-top: 0;
    }
  }

  &__dropdown-link &__list-text {
    height: 50px;

    &:hover {
      background-color: white;
      color: $brand-primary;
    }
  }

  &__list-menu[aria-expanded="true"] &__menu-icon {
    display: block;
    transform: rotate(180deg);
  }
}

@media (min-width: 992px) {
  .c-hotel-sheet-navigation {
    .container {
      padding: 0 10px;
    }

    .row {
      margin: 0 -15px;
    }

    &__list {
      list-style: none;
      padding: 0;
      margin: 0;
      text-transform: uppercase;
    }

    &__list-item {
      font-size: 14px;
      float: left;
      background-color: $color-light-gray;
      transition: background-color 0.3s;

      &:hover {
        background-color: $color-white;

        a {
          text-decoration: none;
        }
      }

      &:last-child {
        display: none;
      }
    }
  }
}
@media (max-width: $screen-md-max) {
  .c-hotel-sheet-navigation .container {
    width: 100%;
  }
}

// How the tabs pop into view or hide awaaaaaay. With <3, Alberto.
@for $i from 1 through 11 {
  .c-hotel-sheet-navigation {
    @media (min-width: (250px * $i) - 100px) {
      &__list-item {
        width: calc(#{100% / $i} - 5px);
      }

      &__list-item:nth-child(#{$i}) {
        display: block;
      }

      &--cells-#{$i} &__list-menu,
      &__list > &__dropdown &__dropdown-item:nth-child(#{$i}) {
        display: none;
      }
    }
  }
}
