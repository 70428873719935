// Fondo blanco para elementos comunes
.o-module {
    &--white {
        background-color: $color-white;
    }
    // marcas
    &--level {
        background-color: $color-level-bg;
    }
    &--redlevel {
        background-color: $color-redlevel-bg;
    }
    
    &--me {
        background-color: $color-me-bg;
    }
    &--royal {
        background-color: $color-royal-bg;
    }
    &--family {
        background-color: $color-family-bg;
    }
    h3 {
        margin: 45px 0 20px 0;
        font-size: 30px;
    }
    &--border-top {
        border-top: 1px solid $color-dark-gray;
    }
    &--bg-email {
        padding: 50px 0;
        background-position: bottom;
        background-size: cover;
        background-image: url("/assets/img/melia.com/section-email.jpg");
        overflow: hidden;
    }
    .title-mr {
        padding: 10px;
        font-size: 24px;
    }

}
@media (max-width: 768px) {
    .o-module h3 {
        margin: 30px 0 10px 0;
    }
}

// Fondo blanco
.bg-white {
    background-color: $color-white;
}

.img-responsive {
    -o-object-fit: cover;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    width: 100%;
}
/* OBJECTS */

// Objeto que aplica estilos a un conjunto de paneles responsive
.o-list-panels {
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $color-gray;

    article { 
        margin: 0;

        .panel-heading {border-bottom: none; }

        p { line-height: 22px; }
    }

    &--lowercase .panel-title {
        text-transform: initial;
        line-height: 20px !important;
    }
}


@media (min-width: $screen-md-min) {
    .o-block-info__popup {
        width: 100%;
        transform: none;
        left: 35px;
    }
}

// A veces es necesario cambiar los paddings en los forms
.o-form-rewards h3 {
    margin-bottom: 8px;
    text-transform: none;
}


// Cabecera título página
.o-header-title {
    border-style: solid;
    border-width: 4px;
    border-top: 0;
    border-right: 0;
    border-left: 0;

    h1 { text-transform: uppercase; }

    &--with-icon .container {
        display: table;
        height: 93px;

        i {
            display: table-cell;
            width: 75px;
            padding-left: 15px;
            padding-bottom: 25px;
            vertical-align: bottom;
            font-size: 25px;
            color: $brand-primary;            
        }

        h1 {
            display: table-cell;
            width: auto;
            vertical-align: bottom;
            padding-bottom: 20px;
        }
    }
}


@media (max-width: $screen-sm-max) {
    .o-header-title { 
       // margin-top: 100px;

      /*  h1 {
            margin-top: -20px;
            margin-bottom: 8px;
        }*/

        &--with-icon .container { 
            height: auto;
            margin-top: -30px;

            i, h1 { vertical-align: middle; }
        }
    }
}
@media (min-width: $screen-md-min) {
   // .o-header-title { margin-top: 120px; }
}


// Objeto para, en mobile, ajustar los estilos al escoger el tipo de tarjeta de regalo.
@media (max-width: $screen-xs-max) {
    .o-type-gift-card-mobile {
        position: absolute;
        left: 0;
        right: 0;

        img { 
            width: 125px;
            margin-right: 10px;
        }

        div.radio {            
            margin-bottom: 25px;
            margin-left: 20px;
        }

        .radio input[type="radio"] { margin-top: 35px; }
    }
}
@media (min-width: $screen-sm-min) {
    .o-type-gift-card-mobile { display: none; }
}

// Objeto para la pantalla de "regalar puntos"
@media (max-width: $screen-xs-max) {
    .o-gift-points {
        display: inline-flex;
        margin: 20px 0 10px;
        width: 100%;

        span { 
            min-width: 185px;
            width: 50%;
        }

        a { 
            width: 50%;
            text-align: center;
        }
    }
}
@media (min-width: 450px) and (max-width: $screen-xs-max) {
    .o-gift-points a { text-align: right; }
}


// Objeto que aplica estilos a las modales de confirmación de envío de tarjeta regalo
.o-gift-card-modal {
    margin-bottom: 20px;
    
    &__img {
        text-align: center;

        img { max-width: 100%; }
    }
}
@media (max-width: $screen-xs-max) {
    .o-gift-card-modal .o-gift-card-modal__img { margin-bottom: 10px; }
}
@media (max-width: $screen-sm-max) {
    .o-gift-card-modal .o-gift-card-modal__img { width: 100%; }
}


// Objeto para forms no editables
.o-readonly {

    input,
    .custom-select,
    .form-control[disabled],
    .form-control[readonly],
    fieldset[disabled] .form-control {        
        border-color: transparent !important;
        cursor: not-allowed !important;
    }

    input {
        padding-left: 15px;

        &.prefijo {
            width: 40px;
            padding-right: 0;
            margin-right: 0;
        }
    }

    textarea {
        margin-top: 1px;
        padding: 8px 0 0 15px;
        resize: none;
    }

    .custom-select {
        margin-top: 0;
        margin-left: -1px;
        border: none; 

        select {
            padding: 0;
            padding-left: 15px;
        }

        &:after { content: ""; }

        &.day,
        &.month,
        &.year {
            width: auto;
        }
    }
    
    span.help-block,
    .o-form__buttons {
        display: none;
    }

    .o-date-group label:last-child,
    .o-phone-group label:last-child {
        background-color: $color-light-gray;
        padding: 13px 12px;
        border-radius: 2px;
    }

    .checkbox-inline,
    .radio-inline {
        cursor: not-allowed !important;
    }

    .custom-checkbox {        
        &:hover,
        span,
        label {
            cursor: not-allowed !important;
        }

        input { cursor: default; }

        label { background-color: transparent; }
    }

    &--no-apply {
        input,
        .custom-select,
        .form-control[disabled],
        .form-control[readonly],
        fieldset[disabled] .form-control {        
            border-color: $color-dark-gray !important;
            cursor: pointer !important;
        }

        input { 
            padding-left: 12px !important; 
            cursor: default !important;

            &:active,
            &:focus {
                border-color: $color-dark-gray !important;
            }
        }

        .custom-select {
            border: 1px solid $color-dark-gray !important; 

            select {
                padding: 6px 5px 6px 12px !important;
            }

            &:after { content: "\e901" !important; }

            &.focus { border-color: $color-dark-gray !important; }
        }
        
    }

    &--enable-buttons .o-form__buttons { display: block; }
}
@-moz-document url-prefix() {
    .o-readonly .custom-select { margin-left: -5px; }
}

//MIRAME!! PADRE DE PERFIL--HOME: col-sm-8 col-md-7 col-lg-6
// Objeto para agrupar campos de fecha.
.o-date-group {

    .custom-select { float: left; }

    .day,
    .year { width: 25%; }

    .day,
    .month { margin-right: 4px; }
    
    .month { width: calc(50% - 8px); }

    @media (min-width: $screen-sm-min) {
        .day, .month, .year { width: auto;}
        .day {
            min-width: 65px;
            max-width: calc(100% - 188px);
        }

        .month {
            min-width: 120px;
            max-width: calc(100% - 168px);
        }

        .year { min-width: 78px; }
    }
}

// Objeto para campos de fecha cuando no tienen tamaño fijo
.o-date {
    padding: 0;

    & > div {
        padding-right: 0;

        &:last-child { 
            padding-right: 15px;
            
            &::after { right: 20px; }
        }

        &::after {
            position: absolute;
            top: 50%;
            right: 5px;
            margin-top: -10px;
            padding: 5px;
            font-family: 'icons_melia_common';
            font-size: 12px;
            background: $color-white;
            color: $color-dark-gray;
            content: "\e901";
        }
    }
}
@media (max-width: $screen-xs-max) {
    .o-date > div { 
        padding-right: 15px;
    
        &::after { right: 20px; }
    }

    .c-slide-panel__body form { margin-top: 15px; }
}


// Objeto para agrupar campos de teléfono.
.o-phone-group {

    .custom-select {
        margin-right: 5px;
        width: 125px;
        float: left;

        option:checked span {
            color: $color-red;
            display: none;
        }
    }

    .telefono {
        width: calc(100% - 130px);
        &-validacion {
            position: relative;
            display: inline-block;
            width: 125px;
            i { right: 12px; }
        }
    }

    @media (min-width: $screen-sm-min) {
        .telefono { width: 125px; }
    }
}


// Objeto para poner título y link con icon en la misma línea.
.o-tit-link-inline {
    margin-bottom: 20px;

    .o-link-group {
        text-align: right;
        margin-top: 40px;
        margin-bottom: 20px;
    }

    &--subtit {
        margin-top: 40px;
        margin-bottom: 10px;

        h5 { font-weight: bold !important; }

        .o-link-group {
            text-align: right;
            margin-top: 20px;
            margin-bottom: 20px;

            button { text-align: right; }
        }
    }
}
@media (max-width: $screen-xs-max) {
    .o-tit-link-inline--subtit { margin-top: 10px; }
}


// Objeto para posicionar los buttons en los formularios.
.o-form__buttons {
    margin-top: 15px;
    margin-bottom: 20px;

    .btn:first-child { margin-right: 10px; }
    
    @media (max-width: $screen-xs-max) {
        & { margin-top: 20px; }
        .btn {
            display: block;
            width: 100%;
        }
        
        .btn:first-child { margin-bottom: 10px; }

        .btn-default:first-child {
            transform: translateY(100%);
            margin-bottom: -10px;
        }
        
        .btn-primary:last-child { transform: translateY(-100%); }
    }
}

// Objeto para mejorar margenes/paddings en los checks de permisos de suscripción de
//   Mi Perfil - Mis Datos - Datos de Contacto.
.o-block-suscription {
    padding-left: 0;
    padding-bottom: 10px;
}

// Efecto listado sobre slide panel
@media (max-width: $screen-xs-max) {
    .o-list-slidepanel {
        position: absolute;
        left: 0;
        right: 0;
        padding-top: 5px;

        li {
            padding: 15px;
            border-bottom: 1px solid $color-border-gray;

            &:first-child { padding: 0px 15px 15px; }
        }
    }
}

// Objeto que pone padding en las modales con formulario.
.o-modal-form { padding: 20px; }


// Objeto que adapta las pestañas con botón para la home pública.
.o-pestanas-header {
    border-top: 1px solid $color-black;

    .nav-justified .btn-primary {
        float: right;
        width: 250px;
        height: 100%;
        border: 0;
        border-radius: 0;
        color: $color-white;
        line-height: 60px;
    }

    .nav-tabs li.active {
        border-bottom: 5px solid;

        a { width: none; }
    }

    #btnRegistro {
        border: none !important;

        a { 
            height: 58px;
            color: $color-white !important;
            line-height: 58px;
        }
    }
}
@media (max-width: $screen-xs-max) {
    .o-pestanas-header .nav-justified>li, .nav-tabs.nav-justified>li {
        float: left;
        width: 33.3%;
        line-height: 55px;

        &:last-child { width: 0; }
    }
}
@media (max-width: $screen-sm-max) {
    .nav-tabs-meliarewards.o-pestanas-header .nav-tabs li {
        max-width: inherit;
    }
}


// Objeto para dar estilos al bloque "social" del registro en la Home Pública
.o-registry-btn-social {
    margin-bottom: 50px;
    text-align: center;

    button {
        position: relative;        

        &.btn-facebook {
            margin-right: 15px;
            padding: 12px 26px 12px 40px;

            i {
                left: 10px;
                top: 8px;
                font-size: 20px;
            }
        }

        &.btn-google {
            padding: 12px 26px 12px 48px;

            i {
                left: 10px;
                top: 4px;
                font-size: 30px;
            }
        }

        i {
            position: absolute;
            right: auto;                       
            color: $color-white;
        }
    }
}
@media (max-width: $screen-xs-max) {
    .o-registry-btn-social button {
        width: 100%;

        &:last-child { margin-top: 10px; }
    }
}


// Objeto para botón de registro en Home Pública.
.o-btn-registry {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 15px;
    background-color: $color-gray;
    z-index: 99;
}
@media (max-width: $screen-xs-max) {
    .o-btn-registry { display: block; }
    .o-btn-registry ~ footer { margin-bottom: 70px; }
}
@media (min-width: $screen-sm-min) {
    .o-btn-registry { display: none; }
}


// Table Layout como Objeto.
@media (min-width: $screen-sm-min) {
    .o-layout-table {
        display: table;
        width: 100%;

        > * { 
            display: table-cell; 
            float: none;
        }
    }
}


// Objeto para pestañas con bloque "titulo" justo encima.
.o-pestanas-with-title {
    .nav-tabs { border: none; }

    .tab-content { overflow: hidden; }

    .border-secondary {
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }

    &--border-top {
        .nav-tabs { border: none; }

        .tab-content { overflow: hidden; }

        .border-secondary {
            border-top-width: 1px;
            border-top-style: solid;
            border-bottom-width: 1px;
            border-bottom-style: solid;
        }
    }
}
@media (max-width: $screen-xs-max) {
    .o-pestanas-with-title--border-top .border-secondary { border: none; }
}


// Objeto para ajustar pestañas de la Home Pública
.o-pestanas-home {
    margin-top: 50px;

    > .border-secondary { border-bottom: 1px solid; }

    .nav-tabs { border: none; }
}

// Objeto que da estilos de listado a texto descriptivo
.o-text-list  ul {
    padding-left: 15px;
    line-height: 20px;
    
    li { list-style: inside; }
}
@media (min-width: $screen-sm-min) {
    .o-text-list  ul:last-child{ columns: 2; }
}


// Objeto que pone distancia en Resumen hacia el Header
@media (max-width: $screen-sm-max) {
    .o-margin-header { margin-top: 50px; }
}
@media (min-width: $screen-md-min) {
    .o-margin-header { margin-top: 100px; }
}


