.c-hotel-sheet-mr-app {
    padding-bottom: 40px;
    overflow: hidden;
    &__text {
        padding: 6px 0px;
        margin-top: 7px;
        margin-bottom: 7px;
        p {
            position: relative;
            top: 15px;
            left: 0px;
            letter-spacing: 1px;
            padding-bottom: 30px;
        }
    }
}

@media (min-width: $screen-md-min) {
    .c-hotel-sheet-mr-app {
        &__text {
            float: right;
            p {
                width: 395px;
                font-size: 16px;
            }
        }
        &__img {
            position: relative;
            left: 30px;
        }
    }
}

@media (max-width: $screen-xs-max) {
    .c-hotel-sheet-mr-app .btn { width: 100% }
}
@media (max-width:767px){
    .c-hotel-sheet-mr-app  {
        margin: 25px;
    }
  }