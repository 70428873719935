.c-hotel-sheet-mr-close {
  position: relative;
  overflow: hidden;
  &__text {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 11;
    width: 500px;
    max-width: calc(100% - 60px);

    p {
      color: $color-white;
      font-size: 44px;
      line-height: 1.4;
    }
    .btn {
      position: relative;
      bottom: 0;
      margin: 0;
      display: block;
      width: 280px;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media (min-width: $screen-sm-min) {
  .c-hotel-sheet-mr-close {
    &__text {
      top: 125px;
      left: 250px;
      width: 480px;
      p {
        font-size: 38px;
        line-height: 1.4;
      }
      .btn {
        font-size: 1em;
        bottom: 0;
        margin: 0;
      }
    }
    img {
      width: 100%;
      left: 0;
    }
  }
}

@media (max-width: $screen-sm-max) {
  .c-hotel-sheet-mr-close {
    &__text {
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
    }

    img {
      min-height: 340px;
    }

    .btn {
      width: 100%;
    }
  }
}
