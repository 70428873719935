.c-hotel-sheet-amaro {
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 40px 0;
  border: none;
  text-align: center;
  background: url("/assets/img/melia.com/bg_parallax.jpg") #efe2ce;
  background-size: contain;
  background-position: center;
  background-attachment: fixed;
  overflow: auto;
  & > div {
    padding: 35px 25px;
    background-color: #efe2ce;
    .logo-rest {
      padding: 30px;
      max-width: 300px;
    }
    .description {
      ul {
        margin-bottom: 10px;
        li {
          line-height: 1.25;
        }
      }
    }
    .btn-primary {
      margin-top: 20px;
      background: #00afa9;
      color: #efe2ce;
      border: none;
    }
  }
}
.c-hotel-sheet-bardot {
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 40px 0;
  border: none;
  text-align: center;
  background: url("/assets/img/melia.com/patron-bardot.jpg") #fff repeat top
    center;
  overflow: auto;
  & > div {
    padding: 35px 25px;
    background-color: $color-white;
    .logo-rest {
      padding: 30px;
      max-width: 300px;
    }
    .description {
      ul {
        margin-bottom: 10px;
        li {
          line-height: 1.25;
        }
      }
    }
    .btn-primary {
      margin-top: 20px;
      background: linear-gradient(
        to right,
        rgba(218, 174, 72, 1) 0%,
        rgba(240, 219, 107, 1) 40%,
        rgba(240, 219, 107, 1) 60%,
        rgba(218, 175, 72, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#daae48', endColorstr='#daaf48', GradientType=1 );
      color: $color-black;
      border: none;
    }
  }
}

@media (max-width: $screen-sm-max) {
  .c-hotel-sheet-amaro,
  .c-hotel-sheet-bardot {
    .btn {
      width: 100%;
    }
  }
}
