@font-face {
    font-family: 'icons_services';
    src:  url('//www1.melia.com/toolib/assets/fonts/icons/icons_services/icons_services.eot?ptb27');
    src:  url('//www1.melia.com/toolib/assets/fonts/icons/icons_services/icons_services.eot?ptb27#iefix') format('embedded-opentype'),
            url('//www1.melia.com/toolib/assets/fonts/icons/icons_services/icons_services.ttf?ptb27') format('truetype'),
            url('//www1.melia.com/toolib/assets/fonts/icons/icons_services/icons_services.woff?ptb27') format('woff'),
            url('//www1.melia.com/toolib/assets/fonts/icons/icons_services/icons_services.svg?ptb27#icons_services') format('svg');
    font-weight: normal;
    font-style: normal;
}



.icon-retro_console:before {
    content: "\e9ab";
  }
  .icon-chromecast_tv:before {
    content: "\e992";
  }
  .icon-esp_tv:before {
    content: "\e993";
  }
  .icon-two_tv:before {
    content: "\e994";
  }
  .icon-dj_sound:before {
    content: "\e995";
  }
  .icon-bluetooth_music:before {
    content: "\e996";
  }
  .icon-sofa_bed:before {
    content: "\e997";
  }
  .icon-kids_hanger:before {
    content: "\e998";
  }
  .icon-kids_assistance:before {
    content: "\e999";
  }
  .icon-beer_minibar:before {
    content: "\e99a";
  }
  .icon-healthy_minibar:before {
    content: "\e99b";
  }
  .icon-games_box:before {
    content: "\e99c";
  }
  .icon-private_garden:before {
    content: "\e99d";
  }
  .icon-yoga_mat:before {
    content: "\e99e";
  }
  .icon-floor_box:before {
    content: "\e99f";
  }
  .icon-usb_charge:before {
    content: "\e9a0";
  }
  .icon-cradle:before {
    content: "\e9a1";
  }
  .icon-room_art:before {
    content: "\e9a2";
  }
  .icon-local_art:before {
    content: "\e9a3";
  }
  .icon-color_kit:before {
    content: "\e9a4";
  }
  .icon-arribal_welcome:before {
    content: "\e9a5";
  }
  .icon-mattress:before {
    content: "\e9a6";
  }
  .icon-water:before {
    content: "\e9a7";
  }
  .icon-sitting_ball:before {
    content: "\e9a8";
  }
  .icon-radio_bluetooth:before {
    content: "\e9a9";
  }
  .icon-pillows:before {
    content: "\e9aa";
  }
  .icon-hour_front_desk:before {
    content: "\e900";
  }
  .icon-additional_bathroom:before {
    content: "\e901";
  }
  .icon-additional_toilet:before {
    content: "\e902";
  }
  .icon-adult_only:before {
    content: "\e903";
  }
  .icon-air_conditioning:before {
    content: "\e904";
  }
  .icon-airport_shuttle:before {
    content: "\e905";
  }
  .icon-alarm_clock:before {
    content: "\e906";
  }
  .icon-area:before {
    content: "\e907";
  }
  .icon-atm_cash_machine_on_site:before {
    content: "\e908";
  }
  .icon-babysitting_child_services:before {
    content: "\e909";
  }
  .icon-balcony:before {
    content: "\e90a";
  }
  .icon-barber_beauty_shop:before {
    content: "\e90b";
  }
  .icon-bath_or_shower:before {
    content: "\e90c";
  }
  .icon-bath:before {
    content: "\e90d";
  }
  .icon-bathrobe:before {
    content: "\e90e";
  }
  .icon-bathroom:before {
    content: "\e90f";
  }
  .icon-beachfront:before {
    content: "\e910";
  }
  .icon-bicycle_rental:before {
    content: "\e911";
  }
  .icon-bikes_available_free:before {
    content: "\e912";
  }
  .icon-bridal_suite:before {
    content: "\e913";
  }
  .icon-business_centre:before {
    content: "\e914";
  }
  .icon-cable_channels:before {
    content: "\e915";
  }
  .icon-car_hire:before {
    content: "\e916";
  }
  .icon-casino:before {
    content: "\e917";
  }
  .icon-childrens_high_chair:before {
    content: "\e918";
  }
  .icon-childrens_playground:before {
    content: "\e919";
  }
  .icon-city_view:before {
    content: "\e91a";
  }
  .icon-coffee_machine:before {
    content: "\e91b";
  }
  .icon-computer:before {
    content: "\e91c";
  }
  .icon-currency_exchange:before {
    content: "\e91d";
  }
  .icon-cycling:before {
    content: "\e91e";
  }
  .icon-daily_maid_service:before {
    content: "\e91f";
  }
  .icon-desk:before {
    content: "\e920";
  }
  .icon-dining_area:before {
    content: "\e921";
  }
  .icon-diving:before {
    content: "\e922";
  }
  .icon-dressing_room:before {
    content: "\e923";
  }
  .icon-dry_cleaning:before {
    content: "\e924";
  }
  .icon-electric_blankets:before {
    content: "\e925";
  }
  .icon-electric_kettle:before {
    content: "\e926";
  }
  .icon-entertainment_staff:before {
    content: "\e927";
  }
  .icon-evening_entertainment:before {
    content: "\e928";
  }
  .icon-executive_lounge_access:before {
    content: "\e929";
  }
  .icon-express_check_in_check_out:before {
    content: "\e92a";
  }
  .icon-extra_long_beds:before {
    content: "\e92b";
  }
  .icon-facilities_for_disabled_guests:before {
    content: "\e92c";
  }
  .icon-family_rooms:before {
    content: "\e92d";
  }
  .icon-fax_photocopying:before {
    content: "\e92e";
  }
  .icon-fitness_centre:before {
    content: "\e92f";
  }
  .icon-flat_screen_tv:before {
    content: "\e930";
  }
  .icon-free_toiletries:before {
    content: "\e931";
  }
  .icon-game_console_nintendo_wii:before {
    content: "\e932";
  }
  .icon-game_console_ps2:before {
    content: "\e933";
  }
  .icon-game_console_ps3:before {
    content: "\e934";
  }
  .icon-game_console_xbox_360:before {
    content: "\e935";
  }
  .icon-game_console:before {
    content: "\e936";
  }
  .icon-games_room:before {
    content: "\e937";
  }
  .icon-garden_view:before {
    content: "\e938";
  }
  .icon-garden:before {
    content: "\e939";
  }
  .icon-gift_shop:before {
    content: "\e93a";
  }
  .icon-golf_course:before {
    content: "\e93b";
  }
  .icon-hairdryer:before {
    content: "\e93c";
  }
  .icon-hammam:before {
    content: "\e93d";
  }
  .icon-heating:before {
    content: "\e93e";
  }
  .icon-hot_spring_bath:before {
    content: "\e93f";
  }
  .icon-hot_tub:before {
    content: "\e940";
  }
  .icon-indoor_pool:before {
    content: "\e941";
  }
  .icon-interconnected_rooms_available:before {
    content: "\e942";
  }
  .icon-ipad:before {
    content: "\e943";
  }
  .icon-ipod_dock:before {
    content: "\e944";
  }
  .icon-iron:before {
    content: "\e945";
  }
  .icon-ironing_facilities:before {
    content: "\e946";
  }
  .icon-ironing_service:before {
    content: "\e947";
  }
  .icon-karaoke:before {
    content: "\e948";
  }
  .icon-kids_club:before {
    content: "\e949";
  }
  .icon-kitchen:before {
    content: "\e94a";
  }
  .icon-kitchenette:before {
    content: "\e94b";
  }
  .icon-lake_view:before {
    content: "\e94c";
  }
  .icon-landmark_view:before {
    content: "\e94d";
  }
  .icon-laptop_safe:before {
    content: "\e94e";
  }
  .icon-laptop:before {
    content: "\e94f";
  }
  .icon-laundry:before {
    content: "\e950";
  }
  .icon-library:before {
    content: "\e951";
  }
  .icon-linen:before {
    content: "\e952";
  }
  .icon-massage:before {
    content: "\e953";
  }
  .icon-meeting_banquet_facilities:before {
    content: "\e954";
  }
  .icon-microwave:before {
    content: "\e955";
  }
  .icon-mini_market_on_site:before {
    content: "\e956";
  }
  .icon-minibar:before {
    content: "\e957";
  }
  .icon-mountain_view:before {
    content: "\e958";
  }
  .icon-nightclub_dj:before {
    content: "\e959";
  }
  .icon-non_smoking_throughout:before {
    content: "\e95a";
  }
  .icon-outdoor_dining_area:before {
    content: "\e95b";
  }
  .icon-outdoor_pool:before {
    content: "\e95c";
  }
  .icon-patio:before {
    content: "\e95d";
  }
  .icon-pay_per_view_channels:before {
    content: "\e95e";
  }
  .icon-pool_view:before {
    content: "\e95f";
  }
  .icon-private_check_in_check_out:before {
    content: "\e960";
  }
  .icon-private_entrance:before {
    content: "\e961";
  }
  .icon-private_pool:before {
    content: "\e962";
  }
  .icon-refrigerator:before {
    content: "\e963";
  }
  .icon-restaurant_a_la_carte:before {
    content: "\e964";
  }
  .icon-restaurant_buffet:before {
    content: "\e965";
  }
  .icon-restaurant:before {
    content: "\e966";
  }
  .icon-riverview:before {
    content: "\e967";
  }
  .icon-room_service:before {
    content: "\e968";
  }
  .icon-safety_deposit_box:before {
    content: "\e969";
  }
  .icon-satellite_channels:before {
    content: "\e96a";
  }
  .icon-sauna:before {
    content: "\e96b";
  }
  .icon-sea_view:before {
    content: "\e96c";
  }
  .icon-shared_lounge_tv_area:before {
    content: "\e96d";
  }
  .icon-shared_toilet:before {
    content: "\e96e";
  }
  .icon-shoeshine:before {
    content: "\e96f";
  }
  .icon-shops:before {
    content: "\e970";
  }
  .icon-shower:before {
    content: "\e971";
  }
  .icon-shuttle_service:before {
    content: "\e972";
  }
  .icon-ski_equipment_hire:before {
    content: "\e973";
  }
  .icon-ski_pass_vendor:before {
    content: "\e974";
  }
  .icon-ski_school:before {
    content: "\e975";
  }
  .icon-ski_storage:before {
    content: "\e976";
  }
  .icon-ski_to_door_access:before {
    content: "\e977";
  }
  .icon-skiing:before {
    content: "\e978";
  }
  .icon-snack_bar:before {
    content: "\e979";
  }
  .icon-snorkelling:before {
    content: "\e97a";
  }
  .icon-sofa:before {
    content: "\e97b";
  }
  .icon-soundproofing:before {
    content: "\e97c";
  }
  .icon-spa_and_wellness_centre:before {
    content: "\e97d";
  }
  .icon-sun_terrace:before {
    content: "\e97e";
  }
  .icon-tea_coffee_maker:before {
    content: "\e97f";
  }
  .icon-tennis_court:before {
    content: "\e980";
  }
  .icon-terrace:before {
    content: "\e981";
  }
  .icon-toaster:before {
    content: "\e982";
  }
  .icon-toilet:before {
    content: "\e983";
  }
  .icon-tour_desk:before {
    content: "\e984";
  }
  .icon-towels:before {
    content: "\e985";
  }
  .icon-trouser_press:before {
    content: "\e986";
  }
  .icon-tv:before {
    content: "\e987";
  }
  .icon-valet_parking:before {
    content: "\e988";
  }
  .icon-video_games:before {
    content: "\e989";
  }
  .icon-view:before {
    content: "\e98a";
  }
  .icon-vip_room_facilities:before {
    content: "\e98b";
  }
  .icon-wake_up_service_alarm_clock:before {
    content: "\e98c";
  }
  .icon-wake_up_service:before {
    content: "\e98d";
  }
  .icon-water_park:before {
    content: "\e98e";
  }
  .icon-wifi:before {
    content: "\e98f";
  }
  .icon-windsurfing:before {
    content: "\e990";
  }
  