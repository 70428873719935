html,body{
	width:100%;
	height:100%;
	margin:0;
	padding:0;
}
.full-size{
	width:100%;
	height:100%
}
#too-hero{
	width:100%;
	height:100%;
}
#too-hero .too-slider{
	width:100%;
	height:100%;
	overflow:hidden;
	position:relative;
}
#too-hero .too-slider .slides .slide{
	width:100%;height:70%;
	position:absolute;
	top:0;
	left:0;
	z-index:0;
	background-position:center center;
	background-size:cover;
	height: 100% !important;
}
#too-hero .too-slider .slides .slide img{
	display:block;
	width: 100% !important;
	height: 100% !important;
	object-fit: cover;
	margin: 0 !important;
}
#too-hero .too-slider .controls a{
	width:40px;
	height:20px;
	position:absolute;
	top:50%;
	z-index:300;
	background-image:url("/toolib/components/hero/img/sprite.png");
	background-size:100%;
	background-repeat:no-repeat;
	-ms-transform:translateX(-50%) translateY(-50%) rotate(90deg);
	-moz-transform:translateX(-50%) translateY(-50%) rotate(90deg);
	-webkit-transform:translateX(-50%) translateY(-50%) rotate(90deg);
	transform:translateX(-50%) translateY(-50%) rotate(90deg)
}
#too-hero .too-slider .controls a.prev-slide{
	background-position:0 -565px;
	left:2rem;
}
#too-hero .too-slider .controls a.next-slide{
	background-position:0 -600px;
	right:0;
}
#too-hero .too-slider .dots{
	width:100%;
	position:absolute;
	bottom:2rem;
	z-index:300;
	text-align:center;
	padding-left:0;
}
#too-hero .too-slider .dots li{
	width:10px;
	height:10px;
	margin:0 .5rem;
	display:inline-block;
	vertical-align:middle
}
#too-hero .too-slider .dots li a{
	width:100%;
	height:100%;
	display:block;
	background-color:#fff;
	border:1px solid #353535;
	border-radius:50%;
	cursor:pointer;
}
#too-hero .too-slider .dots li a.dot-selected{
	background-color:#353535;
	border:2px solid #d5d5d5;
	box-shadow:0 0 0 2px #353535
}
.promotion-text{
	margin-top:10px;
	padding-top:10px;
	position:relative;
	&:before{
		content: "";
		position: absolute;
		top: -5px;
		height: 1px;
		width: 90%;
		z-index: 99999;
		background-color: #fff;
		left: 0;
		text-align: center;
		margin: auto;
		right: 0;
	}
	.pre-head-promotion{
		font-size: 12px;
		text-transform: uppercase;
		padding-bottom:10px;
		font-weight:bold;
	}
	.head-promotion{
		font-size:24px;
		text-transform: uppercase;
	}
	.copy-promotion{
		font-size: 12px;
		margin:7px 0;
	}
	.link-promotion{
		color:#ffffff;
	}
}
.slides-text{
	position: absolute;
	margin: auto;
	color: #fff;
	display: block;
	text-transform: uppercase;
	width: 100%;
	height: 100%;
	z-index: 9999;
	text-align: center;
	&-container{
		position: relative;
		display: table;
		height: 100%;
		vertical-align: middle;
		width: 100%;
		margin:auto;
	}
	&-content{
		display: table-cell;
		height: 100%;
		width: 100%;
		vertical-align: middle;
	}

	.head{
		font-size: 38px;
		text-shadow: 0px 0px 8px rgba(0,0,0,0.5);
		font-weight: bold;
		min-width: 600px;
		width:40%;
		margin:auto;
		margin-bottom:15px;
	}
	.sub-head{
		font-size: 28px;
		width:40%;
		min-width: 600px;
		margin:auto;
		margin-bottom:15px;
	}
	li{
		background: rgba(0,0,0,0.3); 
		display:inline-block;
		width:320px;
		height: 207px;
		vertical-align: top;
		padding:25px;
		.promotion-square{
			display:table;
			height:100%;
			width: 100%;
			text-align: center;
			.content{
				display:table-cell;
				vertical-align: middle;
				.from-main-promo{
					font-size: 31px;
				}
				.amount-main-promo{
					font-size: 62px;
					font-weight: bold;
					color: #f5a48f;
				}
				//promo 2
				.from-promo2{
					font-size:18px;
					font-weight: bold;
				}
				.amount-promo2{
					font-size:42px;
					font-weight: bold;
				}
				.copy-promo2{
					font-size:18px;
					font-weight: bold;
					.highlighted{
						color:#be846a;
					}
					position:relative;
					&:after{
						content:"";
						position: absolute;
						height: 1px;
						background-color: #fff;
						bottom: -10px;
						width: 50%;
						margin: auto;
						left: 0;
						text-align: center;
						display: inline-block;
						right: 0;
					}
				}
				.from-promo3{
					margin-top:20px;
					font-size: 24px;
				}
				.copy-promo3{
					font-size:38px;
					color:#be846a;
					font-weight: bold;
				}
			}
		}
	}
} 
@media (max-width:767px){
	#too-hero .too-slider .dots{
		display:none;
	}
}
.caption{
	//font-weight: 700;
	//font-family: 'avalon', sans-serif;
	position: absolute;
	top: 40%;
	left: 20%;
	right: 20%;
	bottom: inherit;
	z-index: 400;
	padding-top: 20px;
	padding-bottom: 20px;
	color: #fff;
	text-align: center;
	text-shadow: 0 1px 2px rgba(0,0,0,0.6);
	background: rgba(66,66,66,0.5);

	h3{
		color: #fff;
		margin: 0 0 5px 0;
		font-size: 35px;
		text-transform: uppercase;
	}

	.location { 
		margin-top: 12px;
		font-size: 16px;
	}
}
span.stars.three:before {content: "\e91c\e91c\e91c";}
span.stars.four:before {content: "\e91c\e91c\e91c\e91c";}
span.stars.five:before {content: "\e91c\e91c\e91c\e91c\e91c";}
span.stars:before{
    display: inline-block;
    height: 14px;
    font-family: 'icons_melia_common';
    font-size: 24px;
}
span.keys.three:before {content: "\e915\e915\e915";}
span.keys.four:before {content: "\e915\e915\e915\e915";}
span.keys.five:before {content: "\e915\e915\e915\e915\e915";}
span.keys:before{
    display: inline-block;
    height: 14px;
    font-family: 'icons_melia_common';
	font-size: 24px;
	letter-spacing:4px;
}




.c-hotel-sheet-hero.too-slider .gallery{
	position: absolute;
	bottom: 0px;
	left: 0px;
}
@media only screen and (max-width: 767px)  {
	.c-hotel-sheet-hero.too-slider .gallery{
		display: none !important;
	}
}
.c-hotel-sheet-hero.too-slider .gallery .container{
	position: relative;
}
.c-hotel-sheet-hero.too-slider .gallery .galleryClickListener{
	position: absolute;
	bottom: 10px;
	right: 10px;
	padding: 12px 15px;
	background-color: #333;
	z-index: 749;
}
.c-hotel-sheet-hero.too-slider .galleryClickListener a{
	text-decoration: none;
	color: #FFF;
	font-size: 20px;
}

.c-hotel-sheet-hero--home .slide button { margin-top: 10px; }

@media (max-width: $screen-md-max) {
	.c-hotel-sheet-hero--home  .slides .slide img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%); 
	}
}
#too-hero .too-slider {
	min-height:600px !important;
}

