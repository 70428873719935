.c-hotel-sheet-restaurant-detail {
  margin: 0px;
  padding: 40px 0;
  border: none;
  text-align: center;
  background-color: $color-white;
  &__header {
    margin: 0px;
    padding: 40px 0;
    border: none;
    text-align: center;
    background-color: $color-white;
    .logo-rest {
      padding: 30px;
      max-width: 300px;
    }
    .description {
      p {
        line-height: 30px;
      }
    }
  }
  &__carousel {
    padding: 20px;
    width: 55%;
    position: relative;
    float: left;
    text-align: left;
    margin-bottom: 120px;
    color: $color-white;
    &__text {
      width: 70%;
      float: left;
      h1 {
        color: $color-white;
        .line {
          width: 185px;
          height: 5px;
          display: block;
          margin: 20px 0 20px 0;
          background-color: $color-white;
        }
      }
      p {
        line-height: 30px;
      }
    }
    &__image {
      float: right;
      position: absolute;
      width: 110%;
      bottom: -120px;
      right: -80%;
      .carousel-inner {
        padding-bottom: 60px;
        .item {
          & > div {
            max-height: 250px;
            overflow: hidden;
            img {
              width: 100%;
              min-width: 100%;
            }
          }
          .carousel-description {
            position: absolute;
            width: 100%;
            padding: 15px 0;
            text-align: center;
            color: $color-black;
            p {
              line-height: 30px;
            }
          }
        }
      }
      .carousel-indicators {
        position: absolute;
        bottom: 60px;
        height: 20px;
        width: 100%;
        left: inherit;
        margin-left: inherit;
        padding-left: inherit;
        text-align: center;
        z-index: 1;
        li {
          background: $color-white;
        }
        li.active {
          background: $color-amaro-primary;
        }
      }
    }
  }
  .controls {
    position: absolute;
    top: 15px;
    right: 85px;
    .carousel-control.left,
    .carousel-control.right {
      width: 0px;
      left: 0px;
      right: 0px;
      top: 25px;
      opacity: 1;
      background: none;
      background-image: none;
    }
    .carousel-control.left {
      i {
        float: right;
        border-radius: 5px 0px 0px 5px;
      }
    }
    .carousel-control.right {
      i {
        float: left;
        right: auto;
        margin-top: 0;
        border-radius: 0px 5px 5px 0px;
        border-left: 1px solid $color-dark-gray;
      }
    }
    .carousel-control {
      .icon-next {
        &:before {
          content: "\e626";
        }
      }
    }
    i {
      position: absolute;
      display: block;
      top: 50%;
      right: 25%;
      font-size: 17px;
      background: $color-white;
      line-height: 30px;
      height: 30px;
      color: $color-dark-gray;
      width: 40px;
      text-shadow: none;
    }
  }
  #myGallery {
    img {
      width: 100%;
    }
  }
  &--amaro {
    &__carousel {
      background: url("/assets/img/melia.com/carouselRest-amaro.jpg") #00afa9;
      background-size: contain;
    }
  }
  &--amaro--index {
    background: url("/assets/img/melia.com/patron-amaro.png") #fff repeat-x;
  }
  &--bardot {
    padding: 0 10px;
    background-color: #000;
    .container {
      padding-bottom: 40px;
      background-color: $color-white;
    }
    &__carousel {
      background: #000;
      h1 {
        color: #d9ae47;
        .line {
          background-color: #d9ae47;
        }
      }
      .carousel-indicators {
        li {
          &.active {
            background: #000;
          }
        }
      }
    }
  }
  &--bardot--index {
    .container {
      padding: 40px 0 0;
      background: url("/assets/img/melia.com/patron-bardot.jpg") #000 repeat top
        center;
    }
  }
  &--carousel {
    padding-top: 0;
  }
  &--gallery {
    h1 {
      margin: 20px 10px;
      color: $color-amaro-default;
      text-transform: uppercase;
      .line {
        width: 185px;
        height: 5px;
        display: block;
        margin: 20px auto;
        background-color: $color-amaro-default;
      }
    }
  }
  &--gallery--bardot {
    padding: 0;
    background-color: #000;
    .container {
      padding: 0 10px;
      background-color: $color-white;
      h1 {
        color: #000;
        .line {
          background-color: #000;
        }
      }
    }
  }
  &--form {
    h1 {
      margin: 20px 10px;
      color: $color-white;
      text-transform: uppercase;
      .line {
        width: 185px;
        height: 5px;
        display: block;
        margin: 20px auto;
        background-color: $color-white;
      }
    }
    .control-label {
      color: $color-white;
      text-align: left;
      input[type="checkbox"] {
        vertical-align: bottom;
        margin: 0 10px 2px 0;
        cursor: pointer;
      }
      a {
        color: $color-white;
      }
    }
    .btn-primary {
      background-color: $color-amaro-default;
      border: none;
    }
  }
  &--amaro--form {
    background: url("/assets/img/melia.com/reserve-amaro.jpg");
    background-size: cover;
    background-attachment: fixed;
  }
  &--amaro--bg {
    background: url("/assets/img/melia.com/bg_parallax.jpg");
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    .btn-primary {
      background: $color-amaro-primary;
      color: $color-amaro-secondary;
      border: none;
    }
  }
  &--bardot--bg {
    padding: 0;
    background: #000;
    .container {
      padding: 40px 0;
      background: url("/assets/img/melia.com/patron-bardot.jpg") #000 repeat top
        center;
      .content {
        width: 50%;
        background: linear-gradient(
          to right,
          rgba(218, 174, 72, 1) 0%,
          rgba(240, 219, 107, 1) 40%,
          rgba(240, 219, 107, 1) 60%,
          rgba(218, 175, 72, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#daae48', endColorstr='#daaf48', GradientType=1 );
        h1 {
          color: #000;
          .line {
            background-color: #000;
          }
        }
        .btn-primary {
          background: #000;
          color: #d9ae47;
        }
      }
      .content.bg-dif {
        background: $color-white;
        h1 {
          color: #d9ae47;
          .line {
            background-color: #d9ae47;
          }
        }
        p:not(:first-child):before {
          background-color: #000;
        }
      }
    }
  }
  &--bardot--form {
    background: #000;
    h1 {
      color: #d9ae47;
      .line {
        background-color: #d9ae47;
      }
    }
    .btn-primary {
      background: linear-gradient(
        to right,
        rgba(218, 174, 72, 1) 0%,
        rgba(240, 219, 107, 1) 40%,
        rgba(240, 219, 107, 1) 60%,
        rgba(218, 175, 72, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#daae48', endColorstr='#daaf48', GradientType=1 );
      color: #000;
    }
  }
  &--social--events {
    h1 {
      margin: 20px 10px;
      color: $color-amaro-default;
      text-transform: uppercase;
      .line {
        width: 185px;
        height: 5px;
        display: block;
        margin: 20px auto;
        background-color: $color-amaro-default;
      }
    }
    p {
      line-height: 30px;
    }
    .carousel-indicators {
      position: inherit;
      bottom: inherit;
      left: inherit;
      z-index: 1;
      width: inherit;
      margin-left: inherit;
      padding-left: inherit;
      list-style: inherit;
      text-align: inherit;
      li {
        display: inline;
        margin: 0;
        font-size: 12px;
        text-indent: inherit;
        border: none;
        border-radius: 0;
        cursor: pointer;
        text-transform: uppercase;
        &.active {
          margin: 0;
          width: auto;
          height: auto;
          background-color: transparent;
          color: $color-amaro-primary;
        }
        &:not(:first-child):before {
          content: "";
          width: 10px;
          height: 10px;
          display: inline-block;
          margin: auto;
          border-radius: 50%;
          margin: 0px 10px 0 7px;
          background-color: $color-amaro-primary;
        }
      }
    }
  }
  &--social--events--bardot {
    padding: 0;
    background-color: #000;
    .container {
      padding: 40px 10px;
      background-color: $color-white;
      h1 {
        color: #000;
        .line {
          background-color: #000;
        }
      }
      .carousel-indicators {
        li {
          &.active {
            color: #d9ae47;
          }
          &:not(:first-child):before {
            background-color: #d9ae47;
          }
        }
      }
    }
  }
  &--widget {
    padding: 0;
    background-color: transparent;
    .container {
      padding: 0;
    }
  }
  .content {
    width: 50%;
    margin: 0 auto;
    padding: 35px 25px;
    text-align: center;
    background-color: $color-white;
    h1 {
      color: $color-amaro-default;
      text-transform: uppercase;
      .line {
        width: 185px;
        height: 5px;
        display: block;
        margin: 20px auto;
        background-color: $color-amaro-default;
      }
    }
    .btn-primary {
      min-width: 150px;
      margin: 15px 15px 0;
      border: none;
    }
  }
  .content.bg-dif {
    background-color: $color-amaro-secondary;
    ul {
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 10px;
      line-height: 1.4;
    }
    p:not(:first-child):before {
      content: "";
      width: 10px;
      height: 10px;
      display: block;
      margin: auto;
      border-radius: 50%;
      margin-bottom: 10px;
      background-color: $color-amaro-primary;
    }
  }
  .carousel-description {
    padding: 15px 0;
  }
}
/* media queries */
@media (max-width: $screen-xs-max) {
  .c-hotel-sheet-restaurant-detail--amaro,
  .c-hotel-sheet-restaurant-detail--bardot {
    &__carousel {
      width: 65%;
      margin-bottom: 60px;
      .carousel-inner {
        padding-bottom: 0;

        .item .carousel-description {
          display: none;
        }
      }
      .carousel-indicators {
        bottom: 0;
      }
    }
  }
  .c-hotel-sheet-restaurant-detail {
    &__carousel {
      &__image {
        width: 85%;
        right: -55%;
        bottom: -60px;
      }
    }
    .content {
      width: 70%;
    }
    .btn {
      width: 100%;
      margin: 15px 0 0 !important;
    }
  }
}

@media (max-width: $screen-sm-max) {
  .c-hotel-sheet-restaurant-detail {
    .content {
      width: 70%;
    }
  }
}
.c-hotel-sheet-restaurant-detail--bardot--bg {
  .container {
    .content {
      width: 70%;
    }
  }
}
.c-hotel-sheet-restaurant-detail--gallery--bardot {
  .container {
    margin: 0 10px;
    padding: 0;
    width: auto;
  }
}
.c-hotel-sheet-restaurant-detail--social--events--bardot {
  .container {
    margin: 0 10px;
    width: auto;
  }
}

html[dir="rtl"] .c-hotel-sheet-restaurant-detail {
  &__carousel {
    &__text {
      float: right;
      text-align: right;
    }
  }
}
html[dir="rtl"] .c-hotel-sheet-restaurant-detail--form .control-label,
html[dir="rtl"] .c-hotel-sheet-restaurant-detail--form input[type="time"],
html[dir="rtl"] .c-hotel-sheet-restaurant-detail--form input[type="date"] {
  text-align: right;
}
html[dir="rtl"] .c-hotel-sheet-restaurant-detail--form .control-label input[type="checkbox"] {
  margin: 0px 10px 2px 10px !important;
}

@media (max-width: $screen-xs-max) {
  .c-hotel-sheet-restaurant-detail {
    &__carousel {
      width: auto;

      &__image,
      &__text {
        float: none;
        width: auto;
      }

      &__image {
        position: static;
      }
    }
  }
}
@media (max-width:767px){
  .c-hotel-sheet-restaurant-detail{
    .carousel-description {
      p{
        margin:25px;
      } 
    }
    &__header{
      .carousel-description {
        p{
          margin:25px;
        } 
      }
      .description {
        p{
          margin:25px;
        } 
      }
    }
  }
}