.c-hotel-sheet-mr-info {
  overflow: auto;
  .description-foto {
    width: 50%;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
  }
  .right {
    float: right;
  }
  .description-content {
    width: 50%;
    float: left;
    margin-top: 0;
    padding-left: 10px;
    font-size: 14px;
    h4 {
      margin-top: 0;
      font-size: 20px;
    }
    p {
      line-height: 1.4;
      letter-spacing: 1px;
    }
  }
}
