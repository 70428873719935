//Title hotel sheet intro with logo
.c-hotel-sheet-resume-intro {
  position: relative;
  overflow: hidden;
  margin: 0;
  min-height: 500px;
  img {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    left: 0;
  }
  &__content {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    background: $color-white;
    h3 {
      margin: 0 0 20px;
    }
    .box-buttons {
      margin-top: 20px;
      padding-top: 20px;
      text-align: center;
      border-top: 1px solid $color-gray;
    }
  }
}

@media (max-width: $screen-sm-max) {
  .c-hotel-sheet-resume-intro {
    .btn {
      width: 100%;
    }
  }
}
