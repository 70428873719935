.c-hotel-sheet-detail {
    &__title {
        position: relative;
        .back-link {
            position: absolute;
            margin-top: 15px;
            font-size: 14px;
            color: $color-dark-gray;
            text-decoration: none;
            border: none;
            background: none;
            i {
                margin-right: 5px;
                font-size: 10px;
                transition: all 0.5s;
            }
            &:hover {
                i {
                    margin-right: 2px;
                }
            }
        }
        h3 {
            width: 65%;
            margin: 45px auto 20px;
        }
    }
    &__img {
        position: relative;
        img {
            display: block;
            width: 100%;
        }
        .media-icon {
            padding: 10px;
            position: absolute;
            bottom: 10px;
            right: 10px;
            background-color: $color-black;
            font-size: 12px;
            text-decoration: none;
            i {
                margin-left: 0;
                font-size: 15px;
                color: $color-white;

            }
        }
    }
    &__info {
        &--title {
            p {
                margin-bottom: 20px;
                line-height: 1.45;
                color: $color-dark-gray;
                font-size: 15px;
                text-align: center;
            }
        }
        .h1 {
            margin: 30px 0 10px 0;
            text-transform: uppercase;
            font-family: 'lato', sans-serif;
            font-weight: 700;
            font-size: 22px;
        }
        h3 {
            margin-top: 20px;
            margin-bottom: 10px;
            font-family: inherit;
            font-size: 24px;
            font-weight: 500;
            line-height: 1.1;
            color: inherit;
            text-transform: none;
        }
        p {
            margin-bottom: 20px;
            line-height: 1.45;
            color: $color-dark-gray;
            font-size: 15px;
        }
        ul {
            margin-left: 0;
            padding-left: 0;
            list-style: inherit;
            color: $color-dark-gray;
            font-size: 15px;
            li {
                margin-bottom: 10px;
            }
        }
        .aside-outlined {
            margin-top: 50px;
            padding: 15px;
            border: 1px solid $color-gray;
            .h1 {
                margin: 0 0 20px 0;
                padding-bottom: 12px;
                color: $color-dark-gray;
                font-size: 18px;
                border-bottom: 1px solid $color-gray;
            }
            ul {
                list-style: none;
                padding: 0;
                li {
                    margin-bottom: 15px;
                    text-transform: uppercase;
                    i {
                        display: inline-block;
                        margin-left: 0;
                        width: 40px;
                        text-align: center;
                        vertical-align: top;
                        &[class^="icon-"], &[class*=" icon-"] {
                            font-family: 'icons_services' !important;
                        }
                    }
                    span {
                        display: inline-block;
                        width: calc(100% - 45px);
                    }
                }
            }
        }
        .aside-dates{
            width: 100%;
            .btn-dates{
                width: 100%;
                height: 40px;
                margin-top: 15px;
                border: none;
                background: none;
                background-color: $brand-primary;
                text-transform: uppercase;
                color: #FFFFFF;
            }
        }
        .aside-price{
            margin-top: 15px;
            padding: 15px;
            border: 1px solid #dddddd;
            .price-details{
                text-align: right;
                p{
                    margin: 0;
                    color: #000;
                    font-size: 14px;
                }
                p:nth-child(1){
                    text-transform: uppercase;
                    font-weight: bolder;
                    font-size: 15px;
                    color: #000;
                    text-shadow: none;
                }
                p:nth-child(2){
                    font-size: 32px;
                    font-weight: bold;
                    color: #000000;
                    text-shadow: none;
                }
            }
            .btn-booking{
                width: 100%;
                height: 40px;
                margin-top: 25px;
                border: none;
                background: none;
                background-color: $brand-primary;
                text-transform: uppercase;
                color: #FFFFFF;
            }
        }
    }
    &__button {
        text-align: center;
        padding: 20px 0;
    }
}

html[dir="rtl"] .c-hotel-sheet-detail__info .col-md-8 ul {
	margin-right: 15px;
}
@media (max-width:767px){
    .c-hotel-sheet-detail {
        margin: 25px;

        ul{
            margin-left:15px;
        }

        &__info{
            .aside-outlined{
                margin-bottom: 25px;
            }
        }
        &__title {
            .back-link {
                position: relative;
                margin-top: 20px;
            }
            h3 {
                margin: 20px auto;
            }
        }
    }
}