//Title hotel sheet intro with logo
.c-hotel-sheet-breadcrumbs {
    padding: 8px 0;
    border-top: 1px solid $color-dark-gray;
    border-bottom: 1px solid $color-dark-gray;
    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            display: inline-block;
            color: $color-dark-gray;
            a {
                color: $color-black;
                text-decoration: none;
                transition: color .5s ease;
                i {
                    margin-right: 5px;
                    float: left;
                    font-size: 12px;
                    line-height: 1;
                    margin: 0 6px 0 10px;
                }
            }
            a:hover {
                color: $color-dark-gray;
            }
        }
        li + li:before {
            padding: 0 9px 0 4px;
            content: "\e903";
            color: $color-dark-gray;
            font-family: 'icons_melia_common';
            font-size: 9px;
        }
    }
}

html[dir="rtl"] .c-hotel-sheet-breadcrumbs__list li a  {
    margin-right: 15px;
    float: right !important;
}