// Common
@import "../assets/scss/_common/common";

/* CSS MAQ Hotel Directory Destination Desktop Logado */

// Components

@import "../../components/c-header/c-header";

@import "../../components/c-hotel-sheet-breadcrumbs/c-hotel-sheet-breadcrumbs";
@import "../../components/c-hotel-sheet-rewards-promo/c-hotel-sheet-rewards-promo";
@import "../../components/c-hotel-sheet-intro/c-hotel-sheet-intro";
@import "../../components/c-hotel-sheet-navigation/c-hotel-sheet-navigation";
@import "../../components/c-hotel-sheet-buttons-top/c-hotel-sheet-buttons-top";
@import "../../components/c-hotel-sheet-description/c-hotel-sheet-description";
@import "../../components/c-hotel-sheet-images/c-hotel-sheet-images";
@import "../../components/c-hotel-sheet-map/c-hotel-sheet-map";
@import "../../components/c-hotel-sheet-reviews/c-hotel-sheet-reviews";
@import "../../components/c-hotel-sheet-share/c-hotel-sheet-share";
@import "../../components/c-hotel-sheet-hotels/c-hotel-sheet-hotels";
@import "../../components/c-hotel-sheet-room/c-hotel-sheet-room";
@import "../../components/c-hotel-sheet-budget-request/c-hotel-sheet-budget-request";
@import "../../components/c-hotel-sheet-table/c-hotel-sheet-table";
@import "../../components/c-hotel-sheet-resume-intro/c-hotel-sheet-resume-intro";
@import "../../components/c-hotel-sheet-mr/c-hotel-sheet-mr-benefit";
@import "../../components/c-hotel-sheet-img-text/c-hotel-sheet-img-text";
@import "../../components/c-hotel-sheet-info-description/c-hotel-sheet-mr-info";
@import "../../components/c-hotel-sheet-info-description/c-hotel-sheet-info-description";
@import "../../components/c-hotel-sheet-restaurants/c-hotel-sheet-restaurants";
@import "../../components/c-hotel-sheet-mr/c-hotel-sheet-mr-app";
@import "../../components/c-hotel-sheet-mr/c-hotel-sheet-mr-close";
@import "../../components/c-hotel-sheet-email/c-hotel-sheet-email";
@import "../../components/c-hotel-sheet-detail/c-hotel-sheet-detail";
@import "../../components/c-hotel-sheet-restaurant-detail/c-hotel-sheet-restaurant-detail";
@import "../../components/c-cookies-disclaimer/c-cookies-disclaimer";
@import "../../components/c-hotel-sheet-map-hotel/c-hotel-sheet-map-hotel";
@import "../../components/c-credit-resort/c-credit-resort";
@import "../../components/c-hotel-sheet-paragraph/c-hotel-sheet-paragraph";
@import "../../components/c-room-more-info/c-room-more-info";
@import "../../components/c-hotel-sheet-hero/_c-hotel-sheet-hero.scss";
@import "../../components/c-search-landing-destino/c-search.scss";
@import "../../components/c-hotel-sheet-services-includes/c-hotel-sheet-services-includes";
@import '../../components/c-hotel-sheet-services-includes-2/c-hotel-sheet-services-includes-2';

@import "../../components/c-popUp-LastSearch/_c-popUp-LastSearch.scss";

@import "../../components/c-footer-mobile/c-footer-mobile";
@import "../../components/c-footer/c-brands";
@import "../../components/c-hotel-sheet-rooms/_c-hotel-sheet-rooms"; 
@import "../../components/c-home-hero/_qr-module"; 
//@import '../../components/c-search-v2/c-search';

// Common - utilities:
@import "../assets/scss/_common/7-utilities/_utilities";

//para arreglar el conflicto entre el modal en modal.css y thememelia. Arreglar en cuanto se pueda
.modal-header i {
  position: static !important;
}
.modal-content .close {
  width: auto !important;
  height: auto !important;
}
// fix for modals
.modal-header .close {
  margin-top: 0px !important;
}

.modal-header i {
  position: relative !important;
  padding: 0px 0px 0 0px;
}
