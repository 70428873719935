/*qr china module*/
body{overflow-x:hidden;}
#too-hero.has-qr{
	overflow-x:hidden;
	.c-qr-module{
		color:$color-black;
		transition: right .5s ease-in-out;
		position:fixed;
		padding:5px 12px;
		z-index: 9999999;
		top:85px;
		display:none;
		width:175px;
		background-color: rgba(255, 255, 255, 1);
		&__close{
			    width: 33px;
    height: 30px;
    position: absolute;
    left: -33px;
			top:0;
			background-color: rgba(255, 255, 255, 1);
			display:table;
			text-align: center;
			i{
				display:table-cell;
				vertical-align: middle;
				font-size:18px;

			}

		}
		&.animate{
			.c-qr-module__close i:before{
				transform: rotate(180deg);
    			display: inline-block;
			}
		} 
		&__logo{
			text-align:center;
			margin-bottom:10px;
		}
		&__body{
			text-align:center;
			&__title{
				font-size:16px;
				padding-bottom:10px;
				margin-bottom:10px;
				border-bottom:1px solid $brand-primary;
			}
			&__qr img{
				max-width:155px;
			}
			&__copy{
				&__text1{
					padding-bottom:2px;
				}
				&__plus{
					font-weight: bold;
				}
				&__text2{
					padding-bottom: 10px;
					&__discount{
						font-size: 16px;
    					font-weight: bold;
					}
				}
			}

		}
	}
}
/*end qr china module*/