.c-hotel-sheet-table {
  position: relative;
  background-color: $color-white;
  overflow: auto;

  &__header {
    position: sticky;
    top: 15px;
    left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    width: calc(100% - 30px);
  }

  &__header-element {
    border: none !important;
    padding: 0 !important;

    > div {
      padding: 20px 10px;
      height: 50px;
    }

    > div:first-child { 
      height: 60px;
      margin-top: -45px;
    }

    > div:last-child {
      border-top: 1px solid $color-black;
      border-bottom: 1px solid $color-black;
    }

    &:nth-child(-n + 3) > div:first-child { visibility: hidden }
  }

  .custom-select { 
    width: 180px;
    display: inline-block;
  }
  .custom-select select {
    padding-right: 10px;
  }

  &__arrows { 
    position: absolute;
    top: 0;
    right: 0;
    cursor: default;
    display: none;
  }

  &__arrow { 
    padding: 10.5px 10px;
    border: 1px solid #ddd;
    cursor: pointer;
    display: inline-block;
  }

  #spaces {
    thead {

      .sorting,
      .sorting_asc,
      .sorting_desc {
        background-color: transparent;
        background-position-y: 76px;
        background-position-x: calc(100% + 4px);
      }
    }
    th,
    td {
      padding: 20px 10px;
      text-align: center;
    }
    th:first-child,
    td:first-child,
    th:nth-child(2),
    td:nth-child(2) {
      text-align: left;
    }
    i {
      color: $color-gray;
      font-size: 22px;
    }
    thead > .row { border: none }
    tbody {
      li {
        .dtr-title {
          min-width: 180px;
          div {
            float: left;
            margin-right: 20px;
            &:nth-child(2) {
              padding-top: 5px;
            }
          }
        }
      }
    }
    .dtr-data {
      position: relative;
      top: -8px;
    }
  }

  th,
  td {
    &:first-child {
      position: sticky;
      left: 0;
      top: 0;
      z-index: 2;
      background-color: inherit !important;
    }
  }

  th:first-child div {
    background-color: white !important;
    vertical-align: middle;
    padding: 20px 10px;
  }

  &--has-scroll &__arrows { display: inline-block }
  &--has-scroll &__header-element div:first-child { visibility: hidden }
}

html[dir="rtl"] .c-hotel-sheet-table .custom-select:after{
  right:unset !important;
  left:10px;
}

@media (max-width: $screen-xs-max) {
  .c-hotel-sheet-table {
    margin-bottom: 15px;

    table {
      margin-bottom: 0;
      border-bottom: none !important;
    }

    &::-webkit-scrollbar { height: 4px }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: rgb(51, 51, 51); 
    }
  }
}
