//Title hotel sheet intro with logo
.c-hotel-sheet-intro {
    margin-bottom: 25px;
    &__img {
        width: calc(25% - 1ch);
        max-width:200px;
        padding-top: 30px;
        text-align: center;
        display: inline-block;
        vertical-align: middle;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    &__content {
        width: 75%;
        padding: 30px 0 0 0;
        height: 100%;
        display: inline-block;
        vertical-align: middle;
        &--title {
            margin: 0 0 10px 0;
            line-height: 1.1;
            font-size: 33px;
            text-transform: uppercase;
        }
        &--text {
            margin-bottom: 5px;
            font-size: 16px;
            line-height: 20px;
        }
        a {
            font-size: 18px;
            text-transform: uppercase;
            i {
                margin-left: 5px;
            }
        }
    }
}

@media (max-width: $screen-xs-max) {
    .c-hotel-sheet-intro {
        &__img,
        &__content { width: 100% }

        &__img { height: 125px }
    }
}
@media (max-width:767px){
    .c-hotel-sheet-intro {
        margin: 25px;
    }   
}