/************c-hotel-sheet-rooms*************/
.c-hotel-sheet-rooms {
max-height:500px;
overflow:hidden;
width:100%;
    .slick-slider{
        .slick-arrow{
            width: 50px;
            height: 70px;
             /* Fallback for web browsers that don't support RGBa */
            background-color: rgb(0, 0, 0);
            /* RGBa with 0.6 opacity */
            background-color: rgba(0, 0, 0, 0.4);
            /* For IE 5.5 - 7*/
            filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000);
            /* For IE 8*/
            -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000)";
            -webkit-appearance: none;
            cursor: pointer;
            border: none;
            text-indent: -1000px;
            overflow:hidden;
            position:absolute;
            z-index: 999;
            height:70px;
            top:-65px;
            bottom:0;
            margin:auto;
            &.slick-prev{
                left:-18px;                
                &:after{
                    content:"\e902";
                }
            }
            &.slick-next{
                right:15px;
                &:after{
                    content:"\e903";
                    
                }
            }
            &:after{                
                font-size:32px;
                position:absolute;
                left:9px;
                top: 18px;
                text-indent:0px;
                opacity:.5;
                color:$color-white;
                font-family:"icons_melia_common" !important;
            }
        }
    }
    &__single-room{
        width:360px;
        height:468px;
        background-color:$color-white;
        padding:20px;
        float:left;
        margin-right:30px;
        &__head{
            position:relative;
            
            img{
                max-width:100%;
            }
            
            a[data-toggle]:hover{cursor:pointer;}
            a,a:focus{color:#0071C6;cursor:pointer;text-decoration:none;}
            a:hover,a:active{color:#00518F;text-decoration:none;-webkit-transition:color .3s, background-color .3s;transition:color .3s, background-color .3s;outline:none;}
            .media-icon{background:#333;padding:7px;font-size:12px;}
            .media-icon i{
                display: inline-block;               
                font-size: 18px;
                position: relative;
                top: 4px;
                left: 3px;
                color: #fff;
            }
                   
            a{cursor:pointer;}
            a:hover{text-decoration:none;}
            .btn-camera{position:absolute;bottom:15px;right:15px;height:42px;width:42px;}
        }
        &__body{
            padding-top:10px;
            &__title{
                font-size:16px;
                color:$color-dark-gray;
                font-weight:bold;
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                height: 16px;
                white-space: nowrap;
                max-width: 100%;
                margin-top: 8px !important;
            }
            &__text{
                padding-top:10px;
                font-size:13px;
                //height:64px;
                color:$color-dark-gray;              
                line-height:normal;
                display: -webkit-box;
    
                height: 83.2px;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 1.4;
            }
            &__price{
                    float:right;
                    margin-top:15px;
                .from{
                    font-size:12px;
                    text-align:  right;
                    height:15px;
                    line-height:normal;
                    text-transform: uppercase;
                }
                .tax{
                    >*{
                        font-size:12px;
                        text-align:right;
                    }
                    
                }
                .price{font-size:20px;font-weight:bold; text-align:right; height:24px; line-height:normal;}
            }
            a.btn{
                width:100%;
                margin-top:15px;
            }
            
        }
    }
}
.c-hotel-sheet-rooms .slick-slider .slick-arrow{
    z-index:99 !important;
}
/************fin c-hotel-sheet-rooms*************/