/************ POPUP ULTIMAS BUSQUEDAS ****************/

.c-last-search-popup-new *{
    text-transform: initial !important;
    line-height: initial !important;
}

.c-last-search-popup-new {
    position: absolute;
    width: 410px;
    -webkit-box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.61);
    -moz-box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.61);
    box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.61);
    z-index: 999;
    bottom:100% !important;
}

.c-last-search-popup-new .text-init span{
    font-size:16px;
    font-weight: normal;
}


.c-last-search-popup-new a{
    color:#44a2de;
    text-decoration:underline;    
}
.c-last-search-popup-new .nights{

    font-size:10px;
    text-transform:uppercase !important;
}
.c-last-search-popup-new__header{
    text-align: left;
    margin-bottom: 7px;
    font-size: 18px !important;
    padding: 0 21px;
}

.c-last-search-popup-new__header i:before{
    font-size:24px;
    color:#ffffff;
}

.c-last-search-popup-new .c-last-search-popup-new__close {
    position: absolute;
    right: -15px;
    top: -18px;
    color: #fff;
    font-size: 20px;
    z-index: 99999;
    border-radius: 50%;
    background-color: #fff;
    width: 40px;
    height: 40px;
    -webkit-box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.61);
    -moz-box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.61);
    box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.61);
}

.c-last-search-popup-new .c-last-search-popup-new__close {
    text-align: center;
    text-decoration: none;
    display: none;
    
}

.c-last-search-popup-new .c-last-search-popup-new__close i{
    color:#666666;
    margin-right:0px !important;
}

.c-last-search-popup-new .c-last-search-popup-new__close i:before{
    color:#777777;
    font-size: 24px;
    line-height: 39px;
}

.c-last-search-popup-new__header .name{
    font-size: 14px;
    margin-bottom: 24px;
    color:#ffffff !important;
    text-transform: uppercase !important;
}

.c-last-search-popup-new__claim li{
    position:relative;
    font-size: 16px;
    /* margin-bottom: 17px; */
    color:#ffffff;
}

.c-last-search-popup-new__button{
    text-align: center;
    margin-top:15px;
}

.c-last-search-popup-new__button .btn{
    border:1px solid #ffffff;
    color:#ffffff;
    text-decoration:none;
}

.c-last-search-popup-new__register{
    font-size: 16px;
    background-color:#ffffff !important;
    padding: 10px 15px;
    text-align: center;
    color:#616161;
    line-height: 36px;
}

.c-last-search-popup-new__register > div{
    line-height: 28px !important;
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 15px solid #44a2de;
    left: 10%;
    position: absolute;
    top: -10px;
    display: none;
}

.formholder {
    background: #44a2de;
    
    border: 0px solid #000000;
}

.formholder input[type="email"], .formholder input[type="password"] {
    padding: 7px 5px;
    margin: 10px 0;
    width: 96%;
    display: block;
    font-size: 18px;
    border-radius: 5px;
    border: none;
    -webkit-transition: 0.3s linear;
    -moz-transition: 0.3s linear;
    -o-transition: 0.3s linear;
    transition: 0.3s linear;
}

.formholder input[type="email"]:focus, .formholder input[type="password"]:focus {
    outline: none;
    box-shadow: 0 0 1px 1px #1abc9c;
}

.formholder input[type="submit"] {
    background: #1abc9c;
    padding: 10px;
    font-size: 20px;
    display: block;
    width: 100%;
    border: none;
    color: #fff;
    border-radius: 5px;
}

.formholder input[type="submit"]:hover {
    background: #1bc6a4;
}

.randompad {
    padding: 10px 0;
}

.c-last-search-popup-new .formholder {
    background-color:#ffffff;
    /* border-top: 8px solid #44a2de; */
}

.c-last-search-popup-new .c-last-search-popup-new__header .name{
    color: #333 !important;
    font-weight: bold;
    position: relative;
    padding-left: 19px;
    padding-right: 26px !important;
    display: table-cell;
    vertical-align: middle;
}
.c-last-search-popup-new .c-last-search-popup-new__header .name:before {
    content: "\e905";
    font-family: too-be-icons !important;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 15px;
}
.c-last-search-popup-new .c-last-search-popup-new__header i:before{
    color:#44a2de;
}

.c-last-search-popup-new .c-last-search-popup-new__button .btn{
    background-color:#44a2de;
    color:#ffffff;
}

.c-last-search-popup-new .c-last-search-popup-new__claim li{
    color:#85898b;
    display:table;
    width:100%;
    
    text-align:left;
    border-top: 1px dotted #e0e0e0;
}
.c-last-search-popup-new .c-last-search-popup-new__claim  li:nth-child(even){
    background-color:#f9f9f9;
}
.c-last-search-popup-new .c-last-search-popup-new__claim li:hover a{
    background-color:#0075c0;
    color:#ffffff;
    & >*{
        color:#ffffff;
    }

}
.c-last-search-popup-new .c-last-search-popup-new__claim li a{
    padding: 11px 45px;
    text-decoration:none;
    display:block;
    color:#85898b;
}
.c-last-search-popup-new .c-last-search-popup-new__claim li span.text-lastSearch{
    display:table-cell;
    width: 65%;
    vertical-align:bottom;
    font-weight: normal !important;
    font-size: 14px;
}
.c-last-search-popup-new .c-last-search-popup-new__claim li span.text-lastSearch .destine{
    text-transform:uppercase !important;
}
.c-last-search-popup-new .c-last-search-popup-new__claim li span.button-lastSearch{
    display:table-cell;
    width: 35%;
    vertical-align:bottom;
    text-align:right;
    display: none;
}

.c-last-search-popup-new .c-last-search-popup-new__claim li span.button-lastSearch a{
    padding: 5px 18px !important;
    color: white;
    text-decoration: none;
    font-size: 14px;
    display: none;
}

.c-last-search-popup-new .c-last-search-popup-new__register{
    background-color:#f2f2f2 !important;
}

@media (max-width:1024px) {
    .c-last-search-popup-new{
        right:0;
        /*margin-top:10px;*/
    }
    .c-last-search-popup-new .c-last-search-popup-new__close{
        right:7px !important;
    }
    .c-last-search-popup-new .arrow-up{
        left:60% !important;
    }
}

@media (max-width:768px) {
    .c-last-search-popup-new .arrow-up{
        left:75% !important;
    }
}

/************FIN POPUP ULTIMAS BUSQUEDAS ****************/