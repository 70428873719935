//Title hotel sheet intro with logo
.c-hotel-sheet-paragraph {
    padding-bottom: 40px;
    &__content {
        text-align: center;
    }
    &__text {
        text-align: center;
        line-height: 1.4;
        .title {
            margin-bottom: 30px;
            font-size: 16px;
            color: $color-dark-gray;
            text-align: center;
        }
    }
}
@media (max-width:767px){
    .c-hotel-sheet-paragraph {
        margin: 25px;
    }
}