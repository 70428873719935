//Init melia rewards hotel sheet intro with logos
.c-hotel-sheet-mr-benefit {
  &__box {
    background: transparent;
    position: relative;

    padding: 0 25px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 26px;
    margin-bottom: 45px;
  }

  &__image {
    margin: 22px auto;
    width: 140px;
  }

  &__footer {
    text-align: center;
  }

  &__footer-btn {
    clear: both;
    margin: 30px 0;
    text-align: center;
  }
}

@media (max-width: $screen-xs-max) {
  .c-hotel-sheet-mr-benefit {
    &__footer-btn .btn {
      width: 100%;
    }
  }
}

@media (min-width: $screen-sm-min) {
  .c-hotel-sheet-mr-benefit {
    &__box {
      float: left;
      width: 33.3333333%;
      &:nth-child(2) {
        border-right: 1px solid $color-light-gray;
        border-left: 1px solid $color-light-gray;
      }
    }
  }
}
